import { Grid, html as htmlGrid } from 'gridjs'
import SelectMultiple from '../components/SelectMultiple'
import ModalResumo from '../components/ModalResumo.js'
import { capitalize } from 'lodash'
import { CONFIG_GRID_DEFAULT, MONTHS_LIST } from '../constants/reuniao.js'
import { reuniaoService } from '../services/reuniao.service.js'

const { fetchMeetingData } = reuniaoService()

export default {
	emits: [],

	props: {},
	data() {
		return {
			meetingsTable: null,
			reunioesRealizadas: [],
			haveModalResumo: true,

			columns: [],
			usuarios: [],
			anos: [],
			meses: MONTHS_LIST,
			model: {
				ano: '',
				user: [],
				mes_fim: '',
				mes_inicio: '',
				tem_atendimento: 'False',
			},
			query: '',
		}
	},
	components: {
		SelectMultiple,
		ModalResumo,
	},

	methods: {
		openFiltros() {
			modal_padrao_vue.openModal('reunioes_filtros')
		},

		openModal({ currentTarget }, idActive, politicaActive) {
			const id = idActive || currentTarget?.getAttribute('data-id')
			const politica =
				politicaActive || currentTarget?.getAttribute('data-politica')
			this.$refs.modalResumo.OpenModalResumo({
				id,
				politica,
				atendimentoID: null,
			})
		},

		/**
		 * Gera o formato necessário para popular uma tabela no Grid.js
		 *
		 * @param {Array} formattedResults - Matriz contendo o nome da fundação e seus respectivos dados.
		 *        Cada item é um array com o formato [nome_fundacao, atendimentos].
		 * @param {Object} itemFundacao - Objeto com informações da fundação, incluindo a política e o ID.
		 *        Exemplo: { fundacao_id: '123', politica: 'Política Exemplo' }
		 * @param {Object} meetingsData - Dados da API para o preenchimento da tabela, incluindo meses e atendimentos.
		 *        Estrutura esperada: { meses_filtrados: Array, todos_users: Array, ... }
		 *
		 * @return {Array} - Retorna uma matriz contendo arrays com valores formatados, com o formato:
		 *        [[nome_fundacao, officer, data_atendimento, ...]]
		 */
		generateFinalFormat(formattedResults, itemFundacao, meetingsData) {
			const formatFinal = formattedResults.map(
				([nome_fundacao, atendimentos]) => {
					const dadosCliente = itemFundacao[nome_fundacao]
					const arrayOfficers = atendimentos.map(
						(atendimento) => atendimento.usuario_responsavel_atendimento
					)
					const arrayUnico = [...new Set(arrayOfficers.flat())]
					const user = arrayUnico

					const resultDados = [
						dadosCliente.fundacao_id,
						dadosCliente.politica,
						nome_fundacao,
						user,
					]
					meetingsData.meses_filtrados.forEach((item) => {
						const atendimentoEncontrado = atendimentos.find((a) => {
							return a.mes === item.nome
						})

						resultDados.push(
							atendimentoEncontrado && atendimentoEncontrado?.qtd_reunioes > 0
								? [
										`Qtd: ${atendimentoEncontrado.qtd_reunioes}`,
										GLOBAL.formatDateObject(
											new Date(atendimentoEncontrado.data_atendimento)
										),
								  ]
								: ''
						)
					})
					return resultDados
				}
			)
			return formatFinal
		},

		formatGridResults(results) {
			const meetingsByClient = {}
			const itemFundacao = {}
			const formattedResults = []
			const temAtendimento = this.model.tem_atendimento

			Object.keys(results.reunioes_realizadas_por_mes).forEach((mes) => {
				results.reunioes_realizadas_por_mes[mes].forEach((atendimento) => {
					const {
						nome_fundacao,
						usuario_responsavel_atendimento,
						fundacao_id,
						politica,
						concluido,
						data_atendimento,
						qtd_reunioes,
					} = atendimento

					if (
						temAtendimento === 'True' ||
						(temAtendimento === 'False' && !concluido)
					) {
						if (!meetingsByClient[nome_fundacao]) {
							meetingsByClient[nome_fundacao] = []
							itemFundacao[nome_fundacao] = { fundacao_id, politica }
						}
						meetingsByClient[nome_fundacao].push({
							usuario_responsavel_atendimento,
							mes,
							data_atendimento,
							qtd_reunioes,
						})
					}
				})
			})

			for (let cliente in meetingsByClient) {
				formattedResults.push([cliente, meetingsByClient[cliente]])
			}

			const formatFinal = this.generateFinalFormat(
				formattedResults,
				itemFundacao,
				results
			)
			return formatFinal
		},

		iniciarTabelaGenerica(tableSelector, columns, url) {
			const table = document.querySelector(tableSelector)
			const gridInstance = new Grid({
				...CONFIG_GRID_DEFAULT,
				columns,
				server: {
					url: url,
					then: (data) => {
						const { results } = data
						return this.formatGridResults(results)
					},
					total: (data) => {
						const { results } = data
						return this.formatGridResults(results).length
					},
				},
				pagination: {
					enabled: false,
					limit: 10,
					summary: false,
					server: {
						url: (urlApi, page, limit) =>
							`${urlApi}&page=${++page}&paginate_by=${limit}`,
					},
				},
			})

			gridInstance?.render(table)
			gridInstance.on('rowClick', (...args) => {
				const dados = args[1]

				const id = dados.cells[0].data
				const politica = dados.cells[1].data

				this.openModal({}, id, politica)
			})
			return gridInstance
		},

		initializeMeetingsTable(query) {
			let newQuery = query

			if (query === '?') {
				newQuery = '?ano=2024&tem_atendimento=True'
			} else if (!query.includes('tem_atendimento')) {
				newQuery = `${query}&tem_atendimento=True`
			} else if (!query.includes('ano')) {
				newQuery = `${query}&ano=2024`
			}

			this.meetingsTable = this.iniciarTabelaGenerica(
				'#table_reuniao',
				this.columns,
				`${window.origin}/atendimento/reunioes_realizadas_sai/${newQuery}`
			)
		},

		resetMeetingFilters() {
			this.model = {
				ano: '2024',
				user: [],
				mes_fim: '',
				mes_inicio: '',
				tem_atendimento: 'True',
			}
			window.location.href = `${window.location.origin}/clientes/reunioes/fundacao`
		},

		setQueryUrl() {
			this.query = '?'
			const idUsers = this.model?.user?.map((user) => user.value || user)
			const userJoin = idUsers.filter((idUser) => idUser)?.join(',')

			if (userJoin) this.query += `&user=${userJoin}`
			if (this.model.ano) this.query += `&ano=${this.model.ano}`
			if (this.model.mes_inicio)
				this.query += `&mes_inicio=${this.model.mes_inicio}`
			if (this.model.mes_fim) this.query += `&mes_fim=${this.model.mes_fim}`
			if (this.model.tem_atendimento)
				this.query += `&tem_atendimento=${this.model.tem_atendimento}`
		},

		filterMeetings() {
			this.setQueryUrl()
			window.location.href = `${window.location.origin}/clientes/reunioes/fundacao/${this.query}`
		},

		initializeColumns() {
			this.columns = [
				{ id: 'id', name: 'ID', hidden: true },
				{ id: 'politica', name: 'Politica', hidden: true },
				{
					id: 'nome',
					name: 'Fundação',
					hidden: false,
					width: '250px',
					attributes: {
						'sticky-column-1': true,
						'sticky-header': true,
					},
					formatter: (cell, row) => {
						const politica = row?.cells[1]?.data
						return htmlGrid(`<div class="inline-flex  items-center max-w-[250px] " >
							<svg class="h-40 w-40 shrink-0" style="${GLOBAL.generateAvatarColor(politica)}">
									<use xlink:href="#icon_person"></use>
								</svg>	
							<span class="one-line">${cell || ''}</span>
						</div>`)
					},
				},
				{
					id: 'usuario_responsavel_atendimento',
					name: 'Officer',
					hidden: false,
					width: '150px',
					attributes: {
						'sticky-column-2': true,
						'sticky-header': true,
						center: true,
					},
					formatter: (cell, row) => {
						return htmlGrid(`<div style="display:flex; flex-direction:column; gap:2px; max-w-[150px]"'>
							${cell
								?.map((item) => {
									const nameFormated = item
										.split('.')
										.map((word) => capitalize(word))
										.join(' ')
									return `<span>${nameFormated || ''}</span>`
								})
								.join(' ')}
						</div>`)
					},
				},
			]
		},

		processMeetingData(responseMeetingData) {
			responseMeetingData?.results.meses_filtrados.forEach((item) => {
				this.columns.push({
					id: item.id,
					attributes: { 'pointer-events-none': true, center: true },
					name: htmlGrid(
						`<div style="white-space: normal;">${capitalize(item.nome)}</div>`
					),
					width: '90px',
					formatter: (cell) => this.formatMonthCell(cell),
				})
			})
			this.usuarios = responseMeetingData.results.todos_users.map((user) => ({
				label: user.username,
				value: user.id,
			}))
			this.anos = [...responseMeetingData.results.anos]
		},

		initializeModel(urlParams, users) {
			this.model = {
				user:
					this.usuarios.filter((item) => users?.some((i) => i == item.value)) ||
					[],
				ano: Number(urlParams.get('ano')) || '2024',
				mes_inicio: Number(urlParams.get('mes_inicio')) || '',
				mes_fim: Number(urlParams.get('mes_fim')) || '',
				tem_atendimento: urlParams.get('tem_atendimento') || 'True',
			}
		},

		formatOfficerCell(cell) {
			return htmlGrid(`<div style="display:flex; flex-direction:column; gap:2px;">
    ${cell
			?.map((item) => `<span>${this.capitalizeWords(item)}</span>`)
			.join('')}
  </div>`)
		},

		formatMonthCell(cell) {
			return htmlGrid(`<div style="display:flex; flex-direction:column; gap:2px;">
    <span style="font-weight:600;">${cell ? cell[0] : ''}</span>
    <span>${cell ? cell[1] : ''}</span>
  </div>`)
		},
		capitalizeWords(text) {
			return text
				.split('.')
				.map((word) => capitalize(word))
				.join(' ')
		},
	},

	async mounted() {
		this.initializeColumns()
		const urlParams = new URLSearchParams(window.location.search)
		const users = urlParams.get('user')?.trim().split(',')

		const responseMeetingData = await fetchMeetingData(window.location.search)

		this.processMeetingData(responseMeetingData)
		this.initializeModel(urlParams, users)

		this.setQueryUrl()

		this.initializeMeetingsTable(this.query)
	},
	delimiters: ['{*', '*}'],
}
