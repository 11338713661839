import { useApi } from '../composables/useApi'

export function investimentosService() {
	const api = useApi()

	async function getInvestimentosPf(query='') {
		return api.query(
			`${window.origin}/clientes/investimentos/clientes_pf_api/${query}`
		)
	}
	async function getMetaDadosInvestimentos(query='') {
		return api.query(`${window.origin}/clientes/todos_user_sap/${query}`)
	}
	return {
		getInvestimentosPf,
		getMetaDadosInvestimentos,
	}
}
