const { ref } = Vue
import { className, Grid, h, html as htmlGrid } from 'gridjs'
import SelectMultiple from '../components/SelectMultiple'
import ModalResumo from '../components/ModalResumo.js'
import { capitalize } from 'lodash'
import { MONTHS_LIST } from '../constants/reuniao.js'

export default {
	emits: [],

	props: {},
	data() {
		return {
			tableReunioesRealizadas: null,
			reunioesRealizadas: [],
			haveModalResumo: true,
			columns: [
				{ id: 'id', name: 'ID', hidden: true },
				{ id: 'politica', name: 'Politica', hidden: true },
				{
					id: 'nome',
					name: 'Cliente PF',
					attributes: {
						'sticky-column-1': true,
						'sticky-header': true,
					},
					hidden: false,
					width: '180px',
					formatter: (cell, row) => {
						const politica = row?.cells[1]?.data

						return htmlGrid(`<div class="inline-flex items-center max-w-[180px] "  >
							<svg class="h-40 w-40 shrink-0" style="${GLOBAL.generateAvatarColor(politica)}">
									<use xlink:href="#icon_person"></use>
								</svg>
							<span class="one-line max-w-[180px]" title="${cell}">${cell || ''}</span>
						</div>`)
					},
					sort: {
						compare: (a, b) => {
							if (a > b) {
								return 1
							} else if (a < b) {
								return -1
							} else {
								return 0
							}
						},
					},
				},
				{
					id: 'usuario_responsavel_atendimento',
					name: 'Officer',
					hidden: false,
					width: '85px',
					attributes: {
						'sticky-column-2': true,
						'sticky-header': true,
						center: true,
					},
					formatter: (cell, row) => {
						return htmlGrid(`<div style="display:flex; flex-direction:column; gap:2px; max-width:85px; " >
							${cell
								?.map((item) => {
									const nameFormated = item
										.split('.')
										.map((word) => capitalize(word))
										.join(' ')
									return `<span>${nameFormated || ''}</span>`
								})
								.join(' ')}
						</div>`)
					},
				},
			],
			sort: true,
			usuarios: [],
			anos: [],
			meses: MONTHS_LIST,
			mesesClientesSemReuniao: [],
			inicio: '',
			fim: '',
			model: {
				ano: '',
				user: [],
				tem_atendimento: 'false',
				mes_inicio: '',
				mes_fim: 12,
				ano: '',
				ano_fim: '',
				mes_inicio2: 1,
				mes_fim2: '',
			},
			query: '',
		}
	},
	watch: {
		inicio(value) {
			if (!value) return
			const [mes, ano] = value.split('/')
			this.model.mes_inicio = mes
			this.model.ano = ano
		},
		fim(value) {
			if (!value) return
			const [mes, ano] = value.split('/')
			this.model.mes_fim2 = mes
			this.model.ano_fim = ano
		},
	},
	components: {
		SelectMultiple,
		ModalResumo,
	},

	methods: {
		openModal(id, politica) {
			this.$refs.modalResumo.OpenModalResumo({
				id,
				politica,
				atendimentoID: null,
			})
		},

		async getDados(query) {
			try {
				const { data } = await axios.get(
					`${window.origin}/atendimento/reunioes_realizadas/${query}`
				)
				return data
			} catch (error) {
				console.log({ error })
			}
		},
		openFiltros() {
			modal_padrao_vue.openModal('reunioes_filtros')
		},

		formataObjectsInicialFinal(lista_inicial, lista_final) {
			const objectReunioes = {
				...Object.fromEntries(
					Object.entries(lista_inicial).map(([key, value]) => [
						`${key}_inicial`,
						value,
					])
				),
				...Object.fromEntries(
					Object.entries(lista_final).map(([key, value]) => [
						`${key}_final`,
						value,
					])
				),
			}
			return objectReunioes
		},

		formataResultsForGrid(results, resultado = []) {
			const atendimentosPorCliente = {}
			const idCliente = {}
			const temAtendimento = this.model.tem_atendimento
			const dadosReuniao = this.formataObjectsInicialFinal(
				results?.reunioes_ano_inicial,
				results?.reunioes_ano_final
			)

			Object.keys(dadosReuniao)?.forEach((mes) => {
				dadosReuniao[mes]?.forEach((atendimento) => {
					const {
						nome_cliente,
						usuario_responsavel_atendimento,
						cliente_pf_id,
						concluido,
						data_atendimento,
						politica,
						qtd_reunioes,
					} = atendimento
					if (
						temAtendimento === 'true' ||
						(temAtendimento === 'false' && !concluido)
					) {
						if (!atendimentosPorCliente[nome_cliente]) {
							atendimentosPorCliente[nome_cliente] = []
							idCliente[nome_cliente] = { cliente_pf_id, politica }
						}
						atendimentosPorCliente[nome_cliente].push({
							usuario_responsavel_atendimento,
							mes: mes,
							data_atendimento,
							qtd_reunioes,
						})
					}
				})
			})

			for (let cliente in atendimentosPorCliente) {
				resultado.push([cliente, atendimentosPorCliente[cliente]])
			}

			const formatoFinal = resultado.map(([nome_cliente, atendimentos]) => {
				const dadosCliente = idCliente[nome_cliente]
				const arrayOfficers = atendimentos.map(
					(atendimento) => atendimento.usuario_responsavel_atendimento
				)
				const arrayUnico = [...new Set(arrayOfficers.flat())]
				const user = arrayUnico

				const resultado = [
					dadosCliente.cliente_pf_id,
					dadosCliente.politica,
					nome_cliente,
					user,
				]

				const novoArrayInicial = results?.meses_filtrados.map((item) => ({
					...item,
					nome: item.nome + '_inicial',
				}))

				const novoArrayFinal = results?.meses_filtrados2.map((item) => ({
					...item,
					nome: item.nome + '_final',
				}))
				const dadosReuniao = [...novoArrayInicial, ...novoArrayFinal]
				dadosReuniao.forEach((item) => {
					const atendimentoEncontrado = atendimentos.find((a) => {
						return a.mes === item.nome
					})

					resultado.push(
						atendimentoEncontrado && atendimentoEncontrado.data_atendimento
							? [
									`Qtd: ${atendimentoEncontrado.qtd_reunioes}`,
									GLOBAL.formatDateObject(
										new Date(atendimentoEncontrado.data_atendimento)
									),
							  ]
							: ''
					)
				})

				return resultado
			})

			return formatoFinal
		},

		valuesDatasSemFiltro() {
			let newDate = new Date()

			const mesAnterior = Quasar.date.formatDate(newDate, 'MM')
			const anoAtual = Quasar.date.formatDate(newDate, 'YYYY')

			newDate = Quasar.date.subtractFromDate(new Date(), {
				month: 12,
			})

			const mes_12_anterior = Quasar.date.formatDate(newDate, 'MM')
			const ano_12_anterior = Quasar.date.formatDate(newDate, 'YYYY')
			return { mesAnterior, anoAtual, mes_12_anterior, ano_12_anterior }
		},

		async setValuesReunioes() {
			const urlParams = new URLSearchParams(window.location.search)
			const users = urlParams.get('user')?.trim().split(',')

			const { mesAnterior, anoAtual, mes_12_anterior, ano_12_anterior } =
				this.valuesDatasSemFiltro()

			this.model = {
				user:
					this.usuarios.filter((item) => {
						return users?.some((i) => i == item.value)
					}) || [],
				ano: Number(urlParams.get('ano')) || ano_12_anterior,
				mes_inicio2: Number(urlParams.get('mes_inicio2')) || 1,
				mes_fim2: Number(urlParams.get('mes_fim2')) || mesAnterior,
				ano_fim: Number(urlParams.get('ano_fim')) || anoAtual,
				mes_inicio: Number(urlParams.get('mes_inicio')) || mes_12_anterior,
				mes_fim: Number(urlParams.get('mes_fim')) || 12,
				tem_atendimento: urlParams.get('tem_atendimento') || 'true',
			}

			this.inicio = `${this.model.mes_inicio}/${this.model.ano}`
			this.fim = `${this.model.mes_fim2}/${this.model.ano_fim}`

			this.setQueryUrl()
			let responseReunioesRealizadas = await this.getDados(
				window.location.search || this.query
			)

			const novoArrayInicial =
				responseReunioesRealizadas?.results?.meses_filtrados.map((item) => ({
					...item,
					nome: item.nome + '_inicial',
				}))

			const novoArrayFinal =
				responseReunioesRealizadas?.results?.meses_filtrados2.map((item) => ({
					...item,
					nome: item.nome + '_final',
				}))
			const dadosReuniao = [...novoArrayInicial, ...novoArrayFinal]
			// const dadosReuniao = this.formataObjectsInicialFinal(
			// 	responseReunioesRealizadas?.results?.reunioes_ano_inicial,
			// 	responseReunioesRealizadas?.results?.reunioes_ano_
			// )
			dadosReuniao?.forEach((item, index) => {
				this.columns.push({
					attributes: { 'pointer-events-none': true, center: true },
					id: item?.nome,
					name: htmlGrid(
						`<div style="white-space: normal;  ">${capitalize(
							item?.nome?.replace('_final', '')?.replace('_inicial', '')
						)}</div>`
					),
					formatter: (cell, row, column) => {
						const id = row?.cells[0]?.data

						const mesSemReuniao = this.mesesClientesSemReuniao.find(
							(item) => item.id_cliente == id
						)

						const dataFormated = GLOBAL.formatDateObject(
							new Date(mesSemReuniao?.data_reuniao)
						)
						const [_, mes_, __] = dataFormated?.split('/')
						// const ultimoAtendimento = dataFormated

						/*
						let valorEncontrado = false
							for (let i = 0; i < column.index; i++) {
							if (row.cells[i]?.data[1] === ultimoAtendimento) {
								valorEncontrado = true
								console.log(row.cells[i]?.data[1])
								break
							}
						}

						*/

						const ultimoAtendimentoIndice = row.cells.reduce(
							(ultimaData, cellAtual, indexCell) => {
								if (cellAtual.data[1]) {
									return indexCell
								}
								return ultimaData
							},
							-1
						)

						const validaDiasSemReuniao = () => {
							const restantesMeses = row.cells.length - ultimoAtendimentoIndice
							const atualIndex = index + 4

							if (atualIndex > ultimoAtendimentoIndice && restantesMeses > 2) {
								if (restantesMeses - 1 >= 2 && restantesMeses - 1 <= 3) {
									return 'atraso_entre_2_3'
								} else if (restantesMeses - 1 > 3) {
									return 'atraso_maior_3'
								}
							}

							return ''
						}
						validaDiasSemReuniao()
						return htmlGrid(`<div style="display:flex; flex-direction:column; gap:2px;" class="${validaDiasSemReuniao()}">
							<span style="font-weight:600;">${cell ? cell[0] : ''}</span>
   						<span>${cell ? cell[1] : ''}</span>
						</div>`)
					},
					width: '70px',
				})
			})
			this.usuarios = responseReunioesRealizadas?.results?.todos_users?.map(
				(user) => ({ label: user.username, value: user.id })
			)
			this.anos = [...responseReunioesRealizadas?.results?.anos]

			this.model = {
				...this.model,
				user:
					this.usuarios.filter((item) => {
						return users?.some((i) => i == item.value)
					}) || [],
			}
		},

		iniciarTabelaGenerica(
			tableSelector,
			columns,
			url,
			customThenCallback,
			customTotalCallback
		) {
			const table = document.querySelector(tableSelector)

			const gridInstance = new Grid({
				search: false,
				resizable: true,
				columns,
				data: [],
				autoWidth: true,
				width: '100%',
				sort: false,
				server: {
					url: url,
					then: (data) => customThenCallback(data),
					total: (data) => customTotalCallback(data).length,
				},
				pagination: {
					enabled: false,
					limit: 10,
					summary: false,
					server: {
						url: (urlApi, page, limit) =>
							`${urlApi}&page=${++page}&paginate_by=${limit}`,
					},
				},
				style: {
					container: { border: 'none' },
					table: { border: 'none' },
					tr: { border: 'none' },
					th: { 'border-bottom': '1px solid #e9ecef', padding: '8px 8px' },
					td: {
						'border-bottom': '1px solid #e9ecef',
						color: '#495057',

						'font-family': 'Roboto Flex',
						'font-size': '13px',
						'font-weight': 500,
						'line-height': '150%',
						'letter-spacing': '-0.28px',
						padding: '4px',
					},
					footer: { border: '' },
				},
				language: {
					search: { placeholder: 'Busque aqui...' },
					pagination: { previous: ' Anterior', next: 'Próximo ' },
				},
			})
			gridInstance.on('rowClick', (...args) => {
				const dados = args[1]

				const id = dados.cells[0].data
				const politica = dados.cells[1].data

				this.openModal(id, politica)
			})

			gridInstance.on('ready', () => {
				this.paintMesesAtrasados()
			})

			gridInstance?.render(table)

			return gridInstance
		},

		openModalOnBotton(dados) {
			const id = dados.id
			const politica = dados.politica

			this.openModal(id, politica)
		},

		iniciartableReunioesRealizadas(query) {
			const resultado = []
			let newQuery = query

			const mes_fim = 12
			const mes_inicio2 = 1

			const { mesAnterior, anoAtual, mes_12_anterior, ano_12_anterior } =
				this.valuesDatasSemFiltro()
			if (query === '?') {
				newQuery = `?ano=${ano_12_anterior}&mes_inicio=${mes_12_anterior}&mes_fim=${mes_fim}&tem_atendimento=true&ano_fim=${anoAtual}&mes_inicio2=${mes_inicio2}&mes_fim2=${mesAnterior}`
			} else if (!query.includes('tem_atendimento')) {
				newQuery = `${query}&tem_atendimento=true`
			} else if (!query.includes('ano')) {
				newQuery = `${query}&ano=${ano_12_anterior}`
			} else if (!query.includes('mes_fim')) {
				newQuery = `${query}&mes_fim=${mes_fim}`
			} else if (!query.includes('mes_inicio2')) {
				newQuery = `${query}&mes_inicio2=${mes_inicio2}`
			} else if (!query.includes('ano_fim')) {
				newQuery = `${query}&ano_fim=${anoAtual}`
			}

			this.tableReunioesRealizadas = this.iniciarTabelaGenerica(
				'#table_reuniao',
				this.columns,
				`${window.origin}/atendimento/reunioes_realizadas/${newQuery}`,
				(data) => {
					const {
						meses_clientes_sem_reuniao,
						meses_clientes_sem_reuniao_ano_atual,
						results,
					} = data
					this.mesesClientesSemReuniao = [
						...meses_clientes_sem_reuniao,
						...meses_clientes_sem_reuniao_ano_atual,
					]
					return this.formataResultsForGrid(results, resultado)
				},
				(data) => {
					const { results } = data
					return this.formataResultsForGrid(results, resultado)
				}
			)
		},
		limparFiltroReunioesRealizadas() {
			const { mesAnterior, anoAtual, mes_12_anterior, ano_12_anterior } =
				this.valuesDatasSemFiltro()
			this.model = {
				ano: ano_12_anterior,
				ano_fim: anoAtual,
				user: [],
				mes_fim2: mesAnterior,
				mes_inicio2: mes_12_anterior,
				mes_fim: 12,
				mes_inicio2: 1,
				tem_atendimento: 'true',
			}
			window.location.href = `${window.location.origin}/clientes/reunioes/cliente_pf/`
		},

		setQueryUrl() {
			this.query = '?'

			const idUsers = this.model?.user?.map((user) => user.value || user)
			const userJoin = idUsers.filter((idUser) => idUser)?.join(',')

			if (userJoin) this.query += `&user=${userJoin}`
			if (this.model.ano) this.query += `&ano=${this.model.ano}`
			if (this.model.ano_fim) this.query += `&ano_fim=${this.model.ano_fim}`
			if (this.model.mes_inicio2)
				this.query += `&mes_inicio2=${this.model.mes_inicio2}`
			if (this.model.mes_inicio)
				this.query += `&mes_inicio=${this.model.mes_inicio}`
			if (this.model.mes_fim2) this.query += `&mes_fim2=${this.model.mes_fim2}`
			if (this.model.mes_fim) this.query += `&mes_fim=${this.model.mes_fim}`
			if (this.model.tem_atendimento) {
				if (this.model.tem_atendimento === 'ver_todos')
					this.query += `&ver_todos=true`
				else this.query += `&tem_atendimento=${this.model.tem_atendimento}`
			}
		},

		filtrarReunioesRealizadas() {
			this.setQueryUrl()
			window.location.href = `${window.location.origin}/clientes/reunioes/cliente_pf/${this.query}`

			this.paintMesesAtrasados()
		},

		getScrollElement() {
			return this.$refs.gridContainer?.querySelector('.gridjs-wrapper')
		},
		scrollUp() {
			window.scrollBy({ top: -50, behavior: 'smooth' })
		},
		scrollDown() {
			window.scrollBy({ top: 50, behavior: 'smooth' })
		},
		scrollLeft() {
			const container = this.getScrollElement()
			if (container) container.scrollBy({ left: -50, behavior: 'smooth' })
		},
		scrollRight() {
			const container = this.getScrollElement()
			if (container) container.scrollBy({ left: 50, behavior: 'smooth' })
		},

		paintMesesAtrasados() {
			let timeout = 10
			let timeCount = 0

			const checkIdInterval = setInterval(() => {
				timeCount++
				const isTableRender = document.querySelector('.gridjs-td')

				if (isTableRender !== null && isTableRender.hasChildNodes()) {
					this.$nextTick(() => {
						document.querySelectorAll('.gridjs-td').forEach((td) => {
							if (td.querySelector('.atraso_entre_2_3')) {
								td.style.backgroundColor = 'rgba(253, 212, 38, 0.3)'
							}
							if (td.querySelector('.atraso_maior_3')) {
								td.style.backgroundColor = 'rgba(236, 53, 57, 0.3)'
							}
						})
					})

					clearInterval(checkIdInterval)
				} else if (timeCount >= timeout) {
					clearInterval(checkIdInterval)
				}
			}, 1000)
		},
	},

	async mounted() {
		const inputs = document.querySelectorAll('.input-date')

		inputs.forEach((i) => {
			GLOBAL.initFlatpickr(i, {
				position: 'above',
				mode: 'single',
				format: 'm/Y',
				plugins: [
					new monthSelectPlugin({ shorthand: true, dateFormat: 'm/Y' }),
				],
			})
		})

		await this.setValuesReunioes()
		this.setQueryUrl()
		setTimeout(() => {
			this.iniciartableReunioesRealizadas(this.query)
		}, 300)
	},
	delimiters: ['{*', '*}'],
}
