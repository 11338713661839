import Home from './pages/Home'
import TodosClientes from './pages/TodosClientes'
import DashboardGerencial from './pages/DashboardGerencial'
import NotificationsContainer from './components/Notifications'
import NotificationsStates from './mixins/notificationsStates'
import NovoGerencialSap from './pages/NovoGerencialSap'
import Aprovacoes from './pages/Aprovacoes'
import Formulario from './pages/Formulario'
import Reuniao from './pages/Reuniao'
import ReuniaoFundacao from './pages/ReuniaoFundacao'
import RelatorioCaptacao from './pages/RelatorioCaptacao'

const page = () => {
	// console.log("pppppppppp");

	LOG_BLUE(document.querySelector('#RelatorioCaptacao'))
	if (document.querySelector('#Home')) return Home
	else if (document.querySelector('#TodosClientes')) return TodosClientes
	else if (document.querySelector('#DashboardGerencial'))
		return DashboardGerencial
	else if (document.querySelector('#DashboardGerencialSai'))
		return DashboardGerencial
	else if (document.querySelector('#NovoGerencialSap')) return NovoGerencialSap
	else if (document.querySelector('#RelatorioCaptacao'))
		return RelatorioCaptacao
	else if (document.querySelector('#Aprovacoes')) return Aprovacoes
	else if (document.querySelector('#Reuniao')) return Reuniao
	else if (document.querySelector('#Reuniao_Sai')) return ReuniaoFundacao
	else if (document.querySelector("[id^='step']")) return Formulario
	else return null
}

if (!page()) return
const app = Vue.createApp(page())
app.component('NotificationsContainer', NotificationsContainer)
app.mixin(NotificationsStates)
app.use(Quasar)
Quasar.lang.set(Quasar.lang.ptBR)

app.mount('#app')

window.instance = app
