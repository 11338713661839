import AvatarPersona from './AvatarPersona'
import ConfirmModal from './ConfirmModal'
import acessos from '../mixins/acessos'
const { isFundacao, initTipy } = GLOBAL
export default {
	emits: ['enviar', 'open', 'atualiza:processos'],
	props: ['data', 'estrategias', 'fundos', 'semestres'],
	mixins: [acessos],

	data() {
		return {
			editado: false,
			editando: false,
			taskdados: {},
			text: 'Deseja marcar o processo como vencido',
			modalConfirm: false,
		}
	},

	components: {
		AvatarPersona,
		ConfirmModal,
	},
	computed: {
		valorMoney() {
			return GLOBAL.formatReal2(this.data.valor)
		},
	},

	watch: {
		editando() {
			if (this.editando) {
				const estrategiasChoices = GLOBAL.getChoicesActive(
					`estrategia-${this.data.id}`,
					listOfChoices
				)
				estrategiasChoices?.setChoiceByValue(this.data.estrategia)

				const fundoChoices = GLOBAL.getChoicesActive(
					`fundo-${this.data.id}`,
					listOfChoices
				)
				fundoChoices?.setChoiceByValue(this.data.fundo)

				const semestreChoice = GLOBAL.getChoicesActive(
					`semestre-${this.data.id}`,
					listOfChoices
				)
				semestreChoice?.setChoiceByValue(this.data.semestre)
			}
		},
		taskdados: {
			handler() {
				_BIG('taskdados')
			},
			deep: true,
		},
	},

	mounted() {
		if (this.$refs.deadline) GLOBAL.initFlatpickr(this.$refs.deadline)
		const selects = [
			...document.querySelectorAll(
				`.item-processo #estrategia-${this.data.id}, .item-processo #fundo-${this.data.id}, .item-processo #semestre-${this.data.id}`
			),
		]
		const choices = selects.map((i) =>
			GLOBAL.initChoices(i, {
				searchEnabled: false,
				searchChoices: false,
				itemSelectText: 'Selecionar',
				noResultsText: 'Nada Encontrado...',
			})
		)
		setTimeout(() => {
			listOfChoices = [...listOfChoices, ...choices]
			const estrategiasChoices = GLOBAL.getChoicesActive(
				`estrategia-${this.data.id}`,
				listOfChoices
			)
			if (this.estrategias?.length && estrategiasChoices) {
				estrategiasChoices.setChoices(
					[...this.estrategias],
					'value',
					'label',
					true
				)
			}

			const fundoChoices = GLOBAL.getChoicesActive(
				`fundo-${this.data.id}`,
				listOfChoices
			)
			if (!fundoChoices) return
			fundoChoices?.setChoices([...this.fundos], 'value', 'label', true)

			const semestreChoice = GLOBAL.getChoicesActive(
				`semestre-${this.data.id}`,
				listOfChoices
			)
			if (!semestreChoice) return
			semestreChoice?.setChoices([...this.semestres], 'value', 'label', true)
		}, 300)
	},

	methods: {
		getStatus(statusValue) {
			return GLOBAL.taskStatus
				?.filter((i) => statusValue == i.value)
				?.reduce((acc, i) => (acc = i.label), '')
		},

		classStatus(status) {
			return `status-${status}`
		},

		async sendEdicao(e) {
			NProgress.start()

			try {
				const request = await axios.patch(
					`${window.location.origin}/clientes/processos_fundacoes/?id=${this.data.id}`,
					{
						...this.taskdados,
					}
				)
				this.cancelarEdicao()
				this.$emit('enviar', '✅ Tarefa alterada com sucesso')
			} catch (e) {
				console.log(e)
				GLOBAL.showToastify('Ocorreu um erro')
			} finally {
				NProgress.done()
			}
		},

		cancelarEdicao() {
			if (this.editado) {
				this.$emit('atualiza:processos')
			}
			this.taskdados = {}
			this.editado = false
			this.editando = false
			this.$refs.comentario.innerText = this.data.comentario
		},

		handleChange({ target }) {
			const value =
				target.tagName === 'INPUT' || target.tagName === 'SELECT'
					? target.value
					: target.innerText
			this.taskdados[target.getAttribute('name')] = value.trim()
			this.editado = true
		},

		dataFormated(data) {
			return moment(data, 'YYYY/MM/DD').format('DD/MM/YYYY')
		},

		openModalConfirm(status) {
			this.status = status
			this.text = `Deseja alterar status do processo para ${this.status}`
			this.modalConfirm = true
		},

		async updateStatus() {
			NProgress.start()

			try {
				this.cancelarConclusao()

				const request = await axios.patch(
					`${window.location.origin}/clientes/processos_fundacoes/?id=${this.data.id}`,
					{
						status: this.status,
					}
				)

				this.$emit('enviar', ' ✅ Tarefa concluida com sucesso')
				this.modalConfirm = false
			} catch (e) {
				GLOBAL.errorDefault(e)
			} finally {
				NProgress.done()
			}
		},

		cancelarConclusao() {
			this.modalConfirm = false
		},
	},

	template: html`
		<confirm-modal
			v-if="modalConfirm"
			@enviar="updateStatus"
			@cancelar="cancelarConclusao"
			:text="text"
			:modal-data="{id: 'check',header: '',}"></confirm-modal>

		<div
			v-show="isFundacao ? data.fundacao : data.cliente_pf"
			class="table-row  relative group item-processo min-h-[5.5rem]  pb-24 transition-[height]"
			:class="{'editando' : editando, '!min-h-[13rem]': editando }"
			:data-id="data.id">
			<div
				@click="$emit('open')"
				class=" w-40 h-40 cursor-pointer"
				style="grid-area:'icon'">
				<avatar-persona
					class="w-40 h-40"
					:type="data.politica"></avatar-persona>
			</div>

			<p
				@click="$emit('open')"
				class="row-item cursor-pointer"
				:class="{'only-2-lines' : !editando }"
				style="grid-area:'fundacao'">
				{{data.nome_fundacao}}
			</p>
			
<div v-show="editando" class="select-container h-40">
							<select
							@change="handleChange"
								v-model="data.fundo"
								name="fundo"
								:id="'fundo-' + data.id"
								js-choices>
								<option value="" selected>Escolha um fundo</option>
							</select>
						</div>
			<p v-show="!editando"
				@click="$emit('open')"
				class="row-item cursor-pointer "
				:class="{'only-2-lines' : !editando }"
				style="grid-area:'fundo'">
				{{data.sigla_fundo}}
			</p>

			

			<div v-show="editando" class="select-container h-40">
							<select
							@change="handleChange"
								v-model="data.estrategia"
								name="estrategia"
								:id="'estrategia-' + data.id"
								js-choices>
								<option value="" selected>Escolha uma estratégia</option>
							</select>
						</div>

			<p v-show="!editando"
				class="row-item border border-transparent !p-4 rounded-md transition-[height]"
				ref="estrategia"
				style="grid-area:'estrategia'; white-space: break-spaces;"
				@input="handleChange"
				:contenteditable="editando"
				name="estrategia"
			>
				{{data.nome_estrategia}}
			</p>

			<div v-show="editando" class="select-container h-40">
							<select
							@change="handleChange"
								v-model="data.semestre"
								name="semestre"
								:id="'semestre-' + data.id"
								js-choices>
								<option value="" selected>Escolha um semestre</option>
							</select>
						</div>
			<p v-show="!editando"
				@click="$emit('open')"
				class="row-item cursor-pointer "
				:class="{'only-2-lines' : !editando }"
				style="grid-area:'semestre';">
				{{data.semestre_nome}}
			</p>

			<p 
				class="row-item border border-transparent !p-4 rounded-md transition-[height]"
				ref="valor"
				style="grid-area:'valor'; white-space: break-spaces;"
				@input="handleChange"
				:contenteditable="editando"
			
				name="valor"
				>
				{{editando ? data.valor : valorMoney}}
			</p>

			<p v-if=!editando
				class="row-item border border-transparent !p-4 rounded-md transition-[height]"
				ref="comentario"
				style="grid-area:'comentario'; white-space: break-spaces;"
				
				name="comentario"
				>
					<div class="tippy-el" 	:data-tippy-content="data.comentario ? data.comentario : 'Sem comentário' "
							:data-tippy-delay="150"  >
								<q-icon class="text-neutral-60" name="playlist_add_check_circle" size="1.5rem"></q-icon>
							
							</div>
			</p>
			<p v-if="editando"
				class="row-item border border-transparent !p-4 rounded-md transition-[height]"
				ref="comentario"
				style="grid-area:desc !important; white-space: break-spaces;"
				@input="handleChange"
				:contenteditable="editando"
				name="comentario"
				:style="{minHeight: editando ? '70px' : null}">
				{{data.comentario}}
			</p>

			<div
				v-show="!editando"
				style="grid-area:'check'"
				class="flex flex-nowrap gap-4 items-center">
				<div @click="() => openModalConfirm('Vencidos')">
					<svg
						class="w-24 h-24 text-neutral-60 hover:text-green-50 transition-colors cursor-pointer">
						<use xlink:href="#icon_check_processo"></use>
					</svg>
				</div>
				<div @click="() => openModalConfirm('Sem êxito')">
					<svg
						class="w-24 h-24 text-neutral-60 hover:text-alert-red-100 transition-colors cursor-pointer">
						<use xlink:href="#icon_cancel_processo"></use>
					</svg>
				</div>
			</div>

			<button
				type="button"
				v-show="!editando"
				@click="editando = true"
				class="editar absolute  w-32 h-32 opacity-0 group-hover:opacity-100 btn secondary !p-4 right-12 bottom-8">
				<svg class="w-full h-full">
					<use xlink:href="#icon_edit"></use>
				</svg>
			</button>

			<div class="flex justify-between col-span-6 " v-show="editando">
				<button type="button" class="btn secondary" @click="cancelarEdicao">
					Cancelar
				</button>
				<button
					type="button"
					class="btn"
					:class="{'disabled' : !editado }"
					@click="sendEdicao">
					Salvar alteracoes
				</button>
			</div>
		</div>
	`,
}
