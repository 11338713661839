const {
	initChoices,
	setValueInChoices,
	getChoicesActive,
	FormatREAL,
	showToastify,
	estadosBR,
} = GLOBAL

export default {
	props: [
		'clienteAtivo',
		'setPolitica',
		'politicasArr',
		'patrimonio',
		'isFundacao',
		'consultorias',
		'alterarSobre',
	],

	data() {
		return {
			modelValue: {},
			counter: 0,
			estados: estadosBR,
			taxaDisabled: false,
			consultoriasArr: [],
		}
	},

	methods: {
		handleChange(event) {
			const form = event.currentTarget
			const field = event.target
			const checkbox = field.classList.contains('checkInput')

			this.modelValue[field.name] = checkbox ? field.checked : field.value
			//	console.log(this.modelValue);
			//faer verificaçao para checkbox
		},
		onlyNumbers(e) {
			e.target.value = String(e.target.value).replace(/[A-Za-z]/g, '')
		},
		initLabs() {
			const inputDate = document.getElementById('user-edit-input-date')
			GLOBAL.initFlatpickr(inputDate, {
				altInput: true,
				dateFormat: 'YYYY-MM-DD',
				altFormat: 'DD-MM-YYYY',
				allowInput: false,
				parseDate: (datestr, format) => {
					return moment(datestr, format, true).toDate()
				},
				formatDate: (date, format, locale) => {
					// locale can also be used
					return moment(date).format(format)
				},
			})
		},
		dataFormated(data) {
			// console.log(data, moment(data, "YYYY-MM-DD").format("DD/MM/YYYY"), moment(data, "YYYY-MM-DD").toDate());
			return data
		},
		clearEqualZero(e, name) {
			//console.log(e.target, e);
			if (this.clienteAtivo[name] <= 0 && e.target.value <= 0) {
				e.target.value = ''
			}
			// if(patrimonio <= 0){
			// 	console.log(patrimonio);
			// }
		},
		handleChangeTaxa(e) {
			//console.log(e, 'e')
			this.modelValue[e.target.name] = e.target.value
			if (e.keyCode == 8) return
			if (Math.abs(e.target.value) > 100) {
				showToastify('⚠️ Taxa precisa ser inferior ou igual a 100%', {
					className: 'error',
					// node: nomee,
				})
			}
		},
	},

	updated() {
		this.initLabs()
	},

	mounted() {
		this.initLabs()
	},

	watch: {
		modelValue: {
			handler(newValue, oldValue) {
				if (!this.modelValue.gestao && !this.modelValue.consultoria) {
					this.modelValue.taxa = ''
					document.querySelector('#form-sobre input[name="taxa"]').value = ''
					this.taxaDisabled = true
				} else {
					this.taxaDisabled = false
				}

				if (!this.modelValue.data_nascimento) {
					this.modelValue.data_nascimento = null
				}
				//	console.log("EMIT", this.modelValue);
				//	console.log("NEW MODEL", newValue);
				this.$emit('update-cliente-ativo', this.modelValue)
			},
			deep: true,
		},
		clienteAtivo: {
			handler(newValue, oldValue) {
				//	console.log("MODEL", this.clienteAtivo);
				this.modelValue = {
					...newValue,
				}
			},
			deep: true,
		},
		alterarSobre(newValue, oldValue) {
			const el = document.querySelector('#select-consultorias-edicao')
			if (!el) return
			const choiceStarted = el.getAttribute('data-choice')
			if (newValue) {
				let choices
				if (!choiceStarted) {
					choices = initChoices(
						document.querySelector('#select-consultorias-edicao')
					)
					window.listOfChoices = [...window.listOfChoices, choices]
				}

				const choiceActive = getChoicesActive(
					'select-consultorias-edicao',
					window.listOfChoices
				)

				const options = this.consultorias.map((consultoria) => ({
					value: consultoria.id,
					label: consultoria.nome,
					selected: this.clienteAtivo.consultoria_id === consultoria.id,
				}))
				options.unshift({
					value: '',
					label: 'Selecione uma consultoria',
					selected: !this.clienteAtivo.consultoria_id,
				})
				choiceActive.setChoices(options, 'value', 'label', true)
			}
		},
	},

	template: html`
		<form
			class="grid grid-cols-2 gap-16  overflow-hidden"
			id="form-sobre"
			@change="handleChange">
			<div class="flex flex-col text-sm neutral-10 2xl:col-span-2 ">
				<div class="label-icon w-max">
					<label class="label">Nome</label>
				</div>

				<input
					class="input-text h-48 w-full"
					:class="{ 'hover:!border-transparent focus:!border-transparent !border-transparent cursor-not-allowed'  : isFundacao }"
					:readonly="isFundacao"
					js-ativo
					type="text"
					:value="clienteAtivo.nome"
					name="nome" />
			</div>

			<div class="flex flex-col flex-1">
				<label class="label">Politicas</label>

				<div class="select-container h-48">
					<select
						js-choices
						placeholder="placeholder"
						id="select-politica-edicao"
						name="politica">
						<option
							v-for="politica in politicasArr()"
							key="politica.id"
							:value="politica.id">
							{{ politica.name }}
						</option>
					</select>
				</div>
			</div>

			<div class="flex flex-col flex-1">
				<label class="label">Estado</label>

				<div class="select-container h-48">
					<select
						js-choices
						placeholder="placeholder"
						name="estado_id"
						id="estado-modal-select"
						js-choices-props='{"searchEnabled": true, "searchChoices": true, "position":"bottom"}'>
						<option v-for="estado in estados" :value="estado.id">
							{{estado.nome}}
						</option>
					</select>
				</div>
			</div>

			<div class="flex flex-col flex-1" v-if="isFundacao">
				<label class="label">Consultorias</label>

				<div class="select-container h-48" v-if="consultorias?.length">
					<select
						js-choices
						placeholder="placeholder"
						id="select-consultorias-edicao"
						name="consultoria"></select>
				</div>
			</div>

			<div class="flex flex-col text-sm neutral-100  w-full 2xl:col-span-2 ">
				<div class="label-icon w-max">
					<label v-show="!isFundacao" class="label">PLE </label>
				</div>

				<div
					class="flex items-center gap-16"
					:class="{ '!mt-24'  : isFundacao }">
					<div class="relative w-full h-max" v-show="!isFundacao">
						<p
							class="absolute top-0 bottom-0 my-auto h-max z-[2] text-neutral-70 font-medium text-normal left-8">
							R$
						</p>
						<input
							class="input-text h-48 w-full pl-32"
							data-mask="000.000.000.000.000,00"
							data-mask-reverse="true"
							js-ativo
							type="text"
							@focus="(e) => clearEqualZero(e, 'patrimonio_externo')"
							:value="modelValue.patrimonio_externo"
							name="patrimonio_externo" />
					</div>

					<label
						class="radio-container round size-24  inline-flex items-center font-medium gap-8 cursor-pointer">
						<div class="check-label">
							<input
								type="checkbox"
								name="status"
								class="sr-only checkInput"
								:checked="clienteAtivo.status" />
							<span class="radio-circle">
								<i class="radio-circle-inner"></i>
							</span>
						</div>
						Ativado
					</label>
				</div>
			</div>

			<div class="flex gap-8 justify-between items-center ">
				<div class="flex flex-col 2xl:col-span-2 flex-1">
					<label v-show="!isFundacao" class="label">Offshore</label>
					<div class="flex items-center w-full gap-16">
						<div class="relative w-full h-max" v-show="!isFundacao">
							<p
								class="absolute top-0 bottom-0 my-auto h-max z-[2] text-neutral-70 font-medium text-normal left-8">
								R$
							</p>
							<input
								class="input-text h-48 w-full pl-32"
								data-mask="000.000.000.000.000,00"
								data-mask-reverse="true"
								js-ativo
								type="text"
								@focus="(e) => clearEqualZero(e, 'patrimonio_manual')"
								:value="modelValue.patrimonio_manual"
								name="patrimonio_manual" />
						</div>
					</div>
				</div>
				<div class="w-[200px]  flex items-center mt-16">
					<label
						v-show="!isFundacao"
						class="radio-container round size-20 flex-1 inline-flex items-center font-medium gap-8 cursor-pointer">
						<div class="check-label">
							<input
								type="checkbox"
								name="atualizar_automaticamente"
								class="sr-only checkInput"
								:checked="clienteAtivo.atualizar_automaticamente" />
							<span class="radio-circle">
								<i class="radio-circle-inner"></i>
							</span>
						</div>
						Atualização Automática
					</label>
				</div>
			</div>

			<div v-show="!isFundacao" class="flex items-start gap-16  w-full  ">
				<label class="flex-1">
					Data de Nascimento
					<div
						class="flex-1 font-medium text-sm leading-100 text-neutral-70 border border-neutral-30 rounded-md h-max">
						<input
							type="date"
							name="data_nascimento"
							id="user-edit-input-date"
							placeholder="Escolha a data"
							:value="modelValue.data_nascimento ? modelValue.data_nascimento : dataFormated(clienteAtivo.data_nascimento)"
							class="w-full px-10 h-48" />
					</div>
				</label>
			</div>

			<div
				v-show="!isFundacao"
				class="flex gap-16 items-center  col-span-2 w-full">
				<label class="flex-1">
					Profissão
					<input
						class="input-text h-48 w-full "
						js-ativo
						type="text"
						:value="modelValue.profissao"
						name="profissao" />
				</label>
			</div>

			<div v-show="!isFundacao" class="flex  gap-16 items-center  w-full ">
				<label class="flex-1" :class="taxaDisabled ? 'disabled' : ''">
					Taxa de administração (%)
					<input
						class="input-text h-48 w-full"
						@input="onlyNumbers"
						js-ativo
						type="text"
						:value="modelValue.taxa"
						@keyup="handleChangeTaxa"
						name="taxa" />
				</label>
				<label class="flex-1">
					Taxa de performace (%)
					<input
						class="input-text h-48 w-full"
						@input="onlyNumbers"
						js-ativo
						type="text"
						:value="modelValue.taxa_performance"
						@keyup="handleChangeTaxa"
						name="taxa_performance" />
				</label>
			</div>
			<div
				v-show="!isFundacao"
				class="flex flex-1  gap-16 items-center justify-start  w-full ">
				<div
					class="flex gap-8  flex-1 items-center mt-16 flex-col flex-wrap w-full">
					<label
						class="radio-container round size-24 inline-flex items-center font-medium gap-8 cursor-pointer">
						<div class="check-label">
							<input
								type="checkbox"
								name="distribuicao"
								class="sr-only checkInput"
								:checked="clienteAtivo.distribuicao" />
							<span class="radio-circle">
								<i class="radio-circle-inner"></i>
							</span>
						</div>
						Distribuição
					</label>
					<label
						class="radio-container round size-24 inline-flex items-center font-medium gap-8 cursor-pointer">
						<div class="check-label">
							<input
								type="checkbox"
								name="gestao"
								class="sr-only checkInput"
								:checked="clienteAtivo.gestao" />
							<span class="radio-circle">
								<i class="radio-circle-inner"></i>
							</span>
						</div>
						Gestão
					</label>
					<label
						class="radio-container round size-24 inline-flex items-center font-medium gap-8 cursor-pointer">
						<div class="check-label">
							<input
								type="checkbox"
								name="consultoria"
								class="sr-only checkInput"
								:checked="clienteAtivo.consultoria" />
							<span class="radio-circle">
								<i class="radio-circle-inner"></i>
							</span>
						</div>
						Consultoria
					</label>
				</div>
			</div>
		</form>
	`,
}
