//!!! Cadastrar Atendimento
import { capitalize } from 'lodash'
export default {
	props: ['atendimento', 'index', 'contatos', 'officer', 'edite', 'isFundacaoAtendimento',],
	emits:['update-atendimentos', 'open-modal-solicitacao'],
	computed: {
		dataAccordion() {
			return `acc-${this.atendimento.id}`
		},
	},
	data() {
		return {officerString: '', }
	},

	mounted() {
	
		this.officerString = this.formatOfficersNames(this.atendimento?.dados_usuario_responsavel)
		/* console.log({atendimento: this.atendimento}) */
	
	},

	methods: {
		formatOfficersNames(officers) {
			return officers?.map(({nome}) =>
					nome
						.split('.')
						.map((word) => capitalize(word))
						.join(' ')
				)
				.join(', ')
		},

		calculaDiferencaDias(data_criacao) {
			const hoje = new Date()
			const dataCriacao = new Date(data_criacao)
		
			return Quasar.date.getDateDiff(hoje, dataCriacao, 'days')
		},

		isValidEditAtendimento() {
			let diffDias = this.calculaDiferencaDias(this.atendimento.data_criacao)
			let status = ''
			if (this.atendimento?.status_edicao[0]) {
				const [_status, id] = this.atendimento?.status_edicao[0]
				status = _status
			}
			if (status === 'Aprovado' || (  status !== 'Editado' && diffDias <= 30)) return true
			return false
			
		},
	
		openEditAtendimento() {
			if (this.isValidEditAtendimento()) {
				this.$emit("atendimentoData", {...this.atendimento})
				this.$emit('setId', this.atendimento.id)
				this.edite(true)	
			} else {
					this.$emit("open-modal-solicitacao", this.atendimento.id, 'edicao')
			}
		},
		dataFormated(data) {
			
			return data ? moment(data).format('DD/MM/YYYY') : ''
		},

		userFiltrered(userID) {
			const user = todosUsuarios.filter((user) => user.id == userID)
			return user?.length ? user[0].nome : ''
		},

		nomeContatos(arr) {
			if (!Array.isArray(arr)) return ''

			// const reducecallback = (id) => (acc, i) => {
			// 	if (i.id == id) acc = i.nome
			// 	return acc
			// }

			// return arr.map(id => this.contatos.reduce(reducecallback(id), "")).join(", ")
			return arr.join(', \n')
		},
		getTipo(string) {
			if (!string) return ''
			else if (string === '0') return 'Política de atendimento'
			else if (string === '1') return 'Outro'
		},

		getNotificacao(string) {
			if (!string) return ''
			else if (string === '0') return 'Risco de reinvestimento'
			else if (string === '1') return 'Processo de seleção próximo'
			else if (string === '2') return 'Sem notificação'
		},
	},

	updated() {
		this.officerString = this.formatOfficersNames(this.atendimento?.dados_usuario_responsavel)
	},

	template:html`
	<div class="item" v-if="atendimento">
		<div class="flex gap-8 w-full item-borda border border-transparent transition-[border-color] duration-[750]  rounded overflow-hidden">
			<div class="accordion w-full">
				<div class="accordion-item" js-accordion js-accordion-gsap>
					<div class="accordion-title icon-right" :data-accordion="dataAccordion" js-comentario-parent="atendimento-item">
						<span class="text-1-line ">		{{officerString}} - {{dataFormated(atendimento.data_atendimento)}} - {{atendimento.titulo_atendimento}}</span>
						<svg>
							<use xlink:href="#seta_up"></use>
						</svg>
					</div>
	
					<div class="accordion-content" :data-accordion="dataAccordion">
						<div class="accordion-container">
							<div class="p-14">
							<div class="flex w-full justify-end gap-24 pb-8 items-center " :class="{'justify-between': atendimento.status_exclusao?.length > 0 || atendimento.status_edicao?.length > 0}">
								<div class="flex items-center gap-8 w-full">
									<span v-for="tag in atendimento.status_exclusao"
									class="badge  !w-max !h-40 items-center " :class="tag[0]"  js-badge> {{  tag[0] }} (Exclusão)</span>
									<span v-for="tag_edicao in atendimento.status_edicao"
									class="badge  !w-max !h-40 items-center " :class="tag_edicao[0]"  js-badge> {{  tag_edicao[0] }} (Edição)</span>
								</div>
								<div  class="flex  gap-4 items-center">
								<button v-if="!isFundacaoAtendimento" class="btn secondary h-40"  @click="openEditAtendimento">Editar</button>
							<button v-if="!isFundacaoAtendimento" class="btn tertiary p-8  h-40"  @click="$emit('open-modal-solicitacao', atendimento.id, 'delete')" >
								<q-icon size="24px" name="delete" class="cursor-pointer"></q-icon>
							</button>
							</div>
							</div>
								<div v-html="atendimento.atendimento" class="text-neutral-70 text-sm mb-16">
								</div>
								<hr class="border-neutral-30" />
	
								<div v-show="atendimento.tipo" class="flex flex-col gap-4 mt-16">
									<span class="text-neutral-70 text-sm">Tipo</span>
									<span class="text-neutral-90 text-sm">{{getTipo(atendimento.tipo)}}</span>
								</div>
	
								<div v-show="atendimento.contato_atendimento" class="flex flex-col gap-4 mt-16">
									<span class="text-neutral-70 text-sm">Contato</span>
									<span class="text-neutral-90 text-sm" style="white-space: break-spaces;">{{nomeContatos(atendimento.contato_atendimento)}}</span>
								</div>
	
	
								<div  class="flex flex-col gap-4 mt-16" v-show="atendimento.notificacao && atendimento.fundacao_id">
									<span class="text-neutral-70 text-sm">Notificação</span>
									<span class="text-neutral-90 text-sm">{{getNotificacao(atendimento.notificacao)}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="avatar !rounded-md">
				<img src="{% static 'front_assets/dist/images/avatar.png' %}" alt="avatar" />
			</div> -->
		</div>
		<div class="flex justify-end w-full mt-8">
			<p class="text-neutral-80 text-xs mr-40">
			 {{atendimento.data ? new Date(atendimento.data)?.toLocaleDateString('pt-br') : ''}}
			</p>
		</div>
		</div>
		
	`,
}
