import { useApi } from '../composables/useApi'

export function emailFundacaoService() {
	const api = useApi()

	async function getTemplatesEmail(query = '?=') {
		return api.query(`${window.origin}/clientes/email_templates/${query}`)
	}

	async function getFundacoesContatos(query = '?=') {
		return api.query(`${window.origin}/clientes/fundacao_contatos/${query}`)
	}

	async function sendEmail(data, query = '?=') {
		return api.mutation(
			`${window.origin}/clientes/send_email/${query}`,
			'post',
			data
		)
	}

	async function getEmailEnvinhado(id, query = '?=') {
		return api.query(`${window.origin}/clientes/email_enviados/${id}/${query}`)
	}

	return {
		getTemplatesEmail,
		getFundacoesContatos,
		sendEmail,
		getEmailEnvinhado,
		...api,
	}
}
