const { ref } = Vue
import { className, Grid, h, html as htmlGrid } from 'gridjs'
import ConfirmModal from '../components/ConfirmModal.js'
import ModalAprovacao from '../components/ModalAprovacao.js'
import { emailFundacaoService } from '../services/email-fundacao.service.js'
import Quill from 'quill'

const { getChoicesActive, formatDateObject } = GLOBAL

const {
	getTemplatesEmail,
	getFundacoesContatos,
	sendEmail,
	getEmailEnvinhado,
	isLoading,
} = emailFundacaoService()

const refsSelects = {}

export default {
	emits: [],

	props: {},
	components: {
		ModalAprovacao,
		ConfirmModal,
	},

	data() {
		return {
			tableEmails: [],
			listaEmail: true,
			isCreateEmail: true,
			isVisualizaEmail: false,
			templates: {
				results: [],
			},
			fundacoesList: {
				results: [],
			},
			emailsEnvinhados: {},
			emailVisualizacao: {
				template: '',
				fundacoes: [],
				corpo_email: '',
				assunto: '',
			},
			isLoading: isLoading,
			template: '',
			fundacoes: [],
			corpo_email: '',
			assunto: '',

			quill: null,
			quill_visualiza: null,
		}
	},
	watch: {
		template() {
			const templateSelecionado = this.templates?.results?.find(
				(template) => template.id_template === parseInt(this.template)
			)
			console.log('ativou modelo!')
			if (!templateSelecionado) return
			this.assunto = templateSelecionado.assunto
			this.quill.root.innerHTML = templateSelecionado.corpo

		},

		'emailVisualizacao.template'() {
			console.log(this.emailVisualizacao.template)
		},

		fundacoes() {
			/* const fundacoesSelecionados = this.fundacoes.filter((fundacaoSelecionada)=>{
				return this.fundacoesList?.results?.find((fundacao) => fundacao.email_principal === fundacaoSelecionada)
			}) */
			/* console.log(this.fundacoes) */
		},

		fundacoesList() {
			/* console.log(this.fundacoesList) */
			const opcoes = this.fundacoesList?.map((fundacao) => ({
				value: fundacao.id_fundacao,
				label: fundacao.sigla_fundacao,
			}))

			const selectFundacoes = getChoicesActive(
				'email_fundacao',
				window.listOfChoices
			)

			selectFundacoes.setChoices([{ value: '', label: 'Selecione' }, ...opcoes])
			selectFundacoes.setChoiceByValue('')

			refsSelects.selectFundacoes = selectFundacoes
		},

		templates() {
			const opcoes = this.templates?.results?.map((fundacao) => ({
				value: fundacao.id_template,
				label: fundacao.assunto,
			}))

			const selectTemplates = getChoicesActive(
				'email_modelo',
				window.listOfChoices
			)

			selectTemplates.setChoices([{ value: '', label: 'Selecione' }, ...opcoes])
			selectTemplates.setChoiceByValue('')

			refsSelects.selectTemplates = selectTemplates
		},

		isVisualizaEmail() {},
	},
	methods: {
		async onSubmit() {
			const data = {
				template_id: this.template,
				clientes_ids: this.fundacoes,
				corpo: this.quill.root.innerHTML,
			}

			const responseEmail = await sendEmail(data, '')

			console.log(responseEmail)

			this.atualizaTableEmails()
			/* this.closeCreateEmail() */
			window.location.href = `${
				window.location.origin
			}/clientes/email_fundacao/?t=${new Date().getTime()}`
		},

		openCreateEmail() {
			this.listaEmail = false
			this.isCreateEmail = true
		},

		openVisualizaEmail() {
			this.listaEmail = false
			this.isCreateEmail = false
			this.isVisualizaEmail = true
		},

		closeCreateEmail() {
			this.listaEmail = true
			this.isCreateEmail = true
			this.clearCamposEnvioEmail()
		},

		closeVisualizarEmail() {
			this.listaEmail = true
			this.isCreateEmail = true
			this.isVisualizaEmail = false
		},

		/* 		limpaFormEnvioEmail() {
			refsSelects.selectTemplates.setChoiceByValue('')
			refsSelects.selectFundacoes.setChoiceByValue('')
			this.assunto = ''
			this.quill.root.innerHTML = ''
		},
 */
		updateCorpoEmail() {
			if (!this.quill) {
				return
			}
			this.quill.on('text-change', () => {
				const html = this.quill.root.innerHTML
				this.corpo_email = html
			})
		},

		clearCamposEnvioEmail() {
			const selectFundacoes = getChoicesActive(
				'email_fundacao',
				window.listOfChoices
			)

			const selectTemplates = getChoicesActive(
				'email_modelo',
				window.listOfChoices
			)

			selectFundacoes.removeActiveItems()
			selectFundacoes.setChoiceByValue('')
			selectTemplates.removeActiveItems()

			this.fundacao = ''
			this.template = ''
			this.assunto = ''
			this.quill.root.innerHTML = ''
		},

		iniciarTabelaGenerica(
			tableSelector,
			columns,
			url,
			customThenCallback,
			customTotalCallback
		) {
			const table = document.querySelector(tableSelector)

			const gridInstance = new Grid({
				search: true,
				resizable: true,
				columns: columns,
				data: [],
				autoWidth: true,
				width: '100%',
				sort: false,
				server: {
					url: url,
					then: (data) => customThenCallback(data),
					total: (data) => customTotalCallback(data),
				},
				pagination: {
					enabled: true,
					limit: 10,
					summary: false,
					server: {
						url: (urlApi, page, limit) =>
							`${urlApi}&page=${++page}&paginate_by=${limit}`,
					},
				},
				style: {
					container: { border: 'none' },
					table: { border: 'none' },
					tr: { border: 'none' },
					th: { 'border-bottom': '1px solid #e9ecef' },
					td: {
						'border-bottom': '1px solid #e9ecef',
						cursor: 'pointer',
						color: '#495057',
						'font-family': 'Roboto Flex',
						'font-size': '14px',
						'font-weight': 500,
						'line-height': '150%',
						'letter-spacing': '-0.28px',
					},
					footer: { border: 'none' },
				},
				language: {
					search: { placeholder: 'Busque aqui...' },
					pagination: { previous: ' Anterior', next: 'Próximo ' },
				},
			})

			gridInstance.render(table)
			return gridInstance
		},

		iniciartableEmails() {
			const columns = [
				{ id: 'ID', name: 'ID', hidden: true, width: '100px' },

				{ id: 'Para', name: 'Para', width: '10rem' },

				{ id: 'Assunto', name: 'Assunto', hidden: false },
				{
					id: 'Enviados',
					name: 'Enviados',
					formatter: (cell) => this.formatDataEmail(cell),
				},

				{
					id: 'acao',
					name: '',
					width: '80px',
					formatter: (cell, row) => this.renderHistoricoAcoes(row),
				},
			]

			this.tableEmails = this.iniciarTabelaGenerica(
				'#table_emails',
				columns,
				`${window.origin}/clientes/email_enviados/?`,
				(data) => this.mapAndFilterSolicitations(data),
				(data) => this.getTotalEmails(data)
			)
		},

		atualizaTableEmails() {
			this.tableEmails
				.updateConfig({
					server: {
						url: `${window.origin}/clientes/email_enviados/?t=${Date.now()}`,
						then: (data) => this.mapAndFilterSolicitations(data),
						total: (data) => this.getTotalEmails(data),
					},
				})
				.forceRender()
		},

		mapAndFilterSolicitations(data) {
			const listaEmailsEnvinhados = data?.results || []
			this.emailsEnvinhados = listaEmailsEnvinhados
			const emailsEnvinhadosTransformados = listaEmailsEnvinhados.map(
				(emailEnvinhado) => {
					return {
						ID: emailEnvinhado?.id,
						Assunto: emailEnvinhado?.assunto,
						Para: emailEnvinhado?.enviado_para,
						Enviados: emailEnvinhado?.enviado_em,
					}
				}
			)

			/* 			console.log(emailsEnvinhadosTransformados) */

			return emailsEnvinhadosTransformados
		},

		getTotalEmails(data) {
			/* console.log(data.count) */

			return data.count
		},

		formatDataEmail(data) {
			return formatDateObject(new Date(data))
		},

		renderAcoes(row) {
			return h(
				'div',
				{
					className: 'flex gap-2 justify-between',
				},
				[
					h('ion-icon', {
						name: 'checkmark-outline',
						className: 'success',
						onClick: () => this.openModalSolicitacao(row, 'aprovar'),
					}),
					h('ion-icon', {
						name: 'close-outline',
						className: 'error',
						onClick: () => this.openModalSolicitacao(row, 'recusar'),
					}),
				]
			)
		},

		renderHistoricoAcoes(row) {
			return h('ion-icon', {
				name: 'search-outline',
				onClick: async () => await this.getInfoEmailEnvinhado(row),
			})
		},

		async getInfoEmailEnvinhado(row) {
			const idEmail = row?._cells[0]?.data

			/* 			const atualEmailVisualizado = this.emailsEnvinhados.find(
				(emailEnvinhado) => emailEnvinhado.id === idEmail
			) */

			const atualEmailVisualizado = (await getEmailEnvinhado(idEmail))
				?.results[0]

			if (atualEmailVisualizado) {
				console.log(atualEmailVisualizado)

				const opcoesFundacoes = this.fundacoesList?.map((fundacao) => ({
					value: fundacao.id_fundacao,
					label: fundacao.sigla_fundacao,
				}))

				const selectFundacoes = getChoicesActive(
					'email_fundacao_visualisa',
					window.listOfChoices
				)

				selectFundacoes.setChoices([
					{ value: '', label: 'Selecione' },
					...opcoesFundacoes,
				])

				const opcoesTemplates = this.templates?.results?.map((fundacao) => ({
					value: fundacao.id_template,
					label: fundacao.assunto,
				}))

				const selectTemplates = getChoicesActive(
					'email_modelo_visualisa',
					window.listOfChoices
				)

				selectTemplates.setChoices([
					{ value: '', label: 'Selecione' },
					...opcoesTemplates,
				])

				console.log(atualEmailVisualizado.envinhado_para_id)

				selectFundacoes.setChoiceByValue([
					atualEmailVisualizado.envinhado_para_id,
				])
				selectTemplates.setChoiceByValue(atualEmailVisualizado.template_id)

				this.emailVisualizacao.assunto = atualEmailVisualizado.assunto

				this.quill_visualiza.root.innerHTML =
					atualEmailVisualizado.corpo_alterado || atualEmailVisualizado.corpo

				/* selectTemplates.setChoiceByValue('1') 
			this.emailVisualizacao.template = 1; */
				/* this.emailVisualizacao = {
				template: '1',
				fundacoes: [atualEmailVisualizado.envinhado_para_id],
				corpo_email: '',
				assunto: '',
			} */

				this.openVisualizaEmail()
			}
		},
	},

	async mounted() {
		this.quill = new Quill(this.$refs.editor, {
			theme: 'snow',
			modules: {
				toolbar: [
					[{ header: [1, 2, false] }],
					['bold', 'italic', 'underline'],
					[{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
					[{ color: [] }, { background: [] }],
					['clean', 'image'],
				],
			},
		})

		this.quill_visualiza = new Quill(this.$refs.editor_visualisa, {
			theme: 'snow',
			readOnly: true,
			modules: {
				toolbar: [
					[{ header: [1, 2, false] }],
					['bold', 'italic', 'underline'],
					[{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
					[{ color: [] }, { background: [] }],
					['clean', 'image'],
				],
			},
		})

		this.updateCorpoEmail()

		this.fundacoesList = await getFundacoesContatos()
		this.templates = await getTemplatesEmail()

		this.iniciartableEmails()
	},
	delimiters: ['{*', '*}'],
}
