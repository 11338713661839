import { useCarteiraAlvosChart } from '../composables/useCarteiraAlvosChart.js'
import { useCarteiraMarginsChart } from '../composables/useCarteiraMarginsChart.js'
import { useChartTempoVencimento } from '../composables/useChartTempoVencimento.js'
import { useCharts } from '../composables/useCharts.js'
import { useLiquidezAlvosChart } from '../composables/useLiquidezAlvosChart.js'
import { useRatingChart } from '../composables/useRatingChart.js'
import { useLiquidezMarginsChart } from '../composables/useLiquidezMarginsChart.js'
import { useRetorno } from '../composables/useRetorno.js'
import { useVolatilidade } from '../composables/useVolatilidade.js'
import { CARTEIRA_MARGINS_CHART_OPTIONS } from '../constants/carteira_margins.js'
import { clientAssetsService } from '../services/client-assets.service.js'
import { useSetorChart } from '../composables/useSetorChart.js'


const { getDadosTempoVencimento, getDetalhesVolatilidade , getDetalhesRetorno} =
	clientAssetsService()

const { createCarteiraMarginsChart } = useCarteiraMarginsChart()
const { createCarteiraAlvosChart } = useCarteiraAlvosChart()
const { createTempoVencimentoCharts } = useChartTempoVencimento()
const { createLiquidezMarginsChart } = useLiquidezMarginsChart()
const { createLiquidezAlvosChart } = useLiquidezAlvosChart()
const { createRatingChart } = useRatingChart()
const { createSetorChart } = useSetorChart()
const { createVolatilidadeChart } = useVolatilidade()
const { createRetornoChart } = useRetorno()
const {  setColorsInFunctionOfAHexadecimalColor } = useCharts()
export default {
	emits: [],

	data: () => ({
		activeTab: 'investimentos',
		porcentagem_rating: {},
		dadosClienteRating: '',
		buttonRatingAtivo: '',
		rating_chart_fgc:false,
		rating_chart_total: false,
		rating_chart_total_fgc: false,
		emissor_chart: false,
		rating_chart: false,
		tipo_chart: false,
		indice_chart: false,
		divisao_chart: false,
		tempo_vencimento_chart: false,
		ativo_chart: false,
		ativo_table: false,
		tipo_investimento: '',
		total_eventos_de_credito: '',
		ativosTableSortKey: '',
		ativosTableSortOrderInDesc: false,
		infoGraficoModal: null,
		chartError: null,
		isPerfil: false,
		rendaFixa: false,
		isInvestimento: false,
		idCarteira: '',
		dataAtualCarteira: '',
		emissores: {},
		emissores_fgc: {},
		tipos: {},
		indices: {},
		tempo_vencimento: {},
		volatilidade1M: {},
		volatilidade3M: {},
		periodoAtivo: '1M',
		graficoCarteira: null,
		ativosSetor: false,
		limitesLiquidez: {},
		margens: null,
		perfilInvestidor: [],
		data_inicio: '',
		data_fim: '',
		dataInicioFormatted: '',
		dataFimFormatted: '',
		disableAtualizarVolatilidade: false,
		retorno: {},
		filterRetornoMesAtivo: 12
	}),
	watch: {
		
		data_inicio() {
			if (this.data_inicio !== 'DD/MM/YYYY') {
				
				this.formatDate();
			}
			this.ondisableAtualizarVolatilidade()
		},

	

		data_fim() {
			if (this.data_fim !== 'DD/MM/YYYY') {
				
				this.formatDateFim();
			}
			this.ondisableAtualizarVolatilidade()
		}
	},


	methods: {
		   onInput() {
				
      const parts = this.dataInicioFormatted.split("/");
      if (parts.length === 3) {
        const [day, month, year] = parts;
        this.data_inicio = `${year}-${month}-${day}`;
      }
    },


		formatDate() {
      if (this.data_inicio) {
        const [year, month, day] = this.data_inicio.split('/');
        this.dataInicioFormatted = `${day}/${month}/${year}`;
      } else {
        this.dataInicioFormatted = '';
      }
		},
		formatDateFim() {
      if (this.data_fim) {
        const [year, month, day] = this.data_fim.split('/');
        this.dataFimFormatted = `${day}/${month}/${year}`;
      } else {
        this.dataFimFormatted = '';
      }
    },
		async onTabDivisaoClick(name) {
			this.resetButtonsFilters()
			this.ativo_table = false
			this.ativosLiquidez = false
			this.ativosRating = false
			this.isInvestimento = false
			this.destroyAllCharts()
			this.activeTab = name

			switch (name) {
				case 'concentracao':
					this.onConcentracaoTabClick()
					break
				case 'liquidez':
					this.onLiquidezTabClick()
					break
				case 'volatilidade':
					this.onVolatilidadeTabClick()
					break
				case 'retorno':
					this.filterRetornoMesAtivo = 12
					this.onRetornoTabClick()
					break
				case 'investimentos':
					await this.onInvestimentosTabClick()
					break
				default:
					await this.onInvestimentosTabClick()
			}
		},

		 keyVencimento(key) {
			let keyVencimento = ''
			switch (key) {
				case 'Até 5 dias':
					keyVencimento = 'vencimento_5_dias'
					return keyVencimento
					case 'De 6 a 35 dias':
					keyVencimento = 'vencimento_6_a_35_dias'
					return keyVencimento
					
				case 'De 36 a 65 dias':
					keyVencimento = 'vencimento_36_a_65_dias'
					return keyVencimento
					case 'Acima de 65 dias':
					keyVencimento = 'vencimento_65_dias'
					return keyVencimento
				default:
					return keyVencimento
				
				
					
			}
			 
			 
		},
		async setTableAtivosLiquidez(valores) {
				GLOBAL.destroyApexChart(['chartCarteiraAlvos', 'chartCarteiraMargens'])
				this.ativosLiquidez = true
				this.isPerfil = false
				NProgress.start()
			
				try {
					const table = document.querySelector('#table_ativos_liquidez')
					const bodyTable = table?.querySelector('tbody')
					
					this.graficoCarteira =  valores?.map((i) => ({
							valor: i[0].valor,
							tipo_investimento: i[0].nome,
							pct_global: i[0].pct_global,
							pct_renda_fixa: i[0].pct_tipo_investimento,
							indexador: i[0].indexador,
							taxa: i[0].taxa,
							vencimento: i[0].vencimento,
						}))
					bodyTable.innerHTML = this.templateBodyTable(this.graficoCarteira)
					
				} catch (e) {
					GLOBAL.errorDefault(e)
				} finally {
					NProgress.done()
				}
		},

		async onLiquidezTabClick() {
			this.isPerfil = true
			const limites_liquidez = await this.getLimitesLiquidez(this.clienteAtivo.id)
			const { vencimento_5_dias, vencimento_6_a_35_dias, vencimento_36_a_65_dias, vencimento_65_dias } = await this.getDadosInvestimentosClientesRendaFixaLiquidez('Renda Fixa')
			const dadosRendaFixa = {vencimento_5_dias, vencimento_6_a_35_dias,  vencimento_36_a_65_dias, vencimento_65_dias}
			this.limitesLiquidez = limites_liquidez
			setTimeout(() => {
				const t = this
				createLiquidezMarginsChart(this.limitesLiquidez, dadosRendaFixa)
				const chart = Apex._chartInstances?.filter((i) => i.id == 'chartCarteiraMargens')?.[0]
				if (chart) {
					chart.chart.updateOptions({
						chart: {
						...CARTEIRA_MARGINS_CHART_OPTIONS.chart,
						events: {
							click: async function (event, chartContext, config) {
								const key = config?.globals?.labels[config?.dataPointIndex]
								t.periodo_ativo_liquidez = key
								const keyVenc = t.keyVencimento(key)
								const valores = Object.values(dadosRendaFixa[keyVenc])
								
									t.setTableAtivosLiquidez(valores)
								},
							},
						},
						
					})
				}
				createLiquidezAlvosChart(this.limitesLiquidez)
			}, 500)
		},

		async onVolatilidadeTabClick() {
			this.isPerfil = true
			
			this.ondisableAtualizarVolatilidade()
			this.volatilidade1M = await this.getInfoDetalhesVolatilidade('1M', this.data_inicio.replaceAll('/','-'), this.data_fim.replaceAll('/','-'))
			this.volatilidade3M = await this.getInfoDetalhesVolatilidade('3M', this.data_inicio.replaceAll('/','-'), this.data_fim.replaceAll('/','-'))

			const dados_1m = {
				volatilidades: this.volatilidade1M?.volatilidades.map(
					(v) => v.volatilidade
				),
				labels: this.volatilidade1M?.volatilidades.map((v) =>
					v?.data.replaceAll('-','/')
				),
				rentabilidades: this.volatilidade1M?.volatilidades.map(
					(v) => v.rentabilidade_diaria
				),
				periodo: this.volatilidade1M?.volatilidades_perfil.find(
					(item) => this.volatilidade1M.periodo === item.periodo 
				) || {periodo: '1M'},
				message: this.volatilidade1M?.volatilidades_perfil[0]?.mensagem,
				estatisticas: this.volatilidade1M?.estatisticas
			}

			const dados_3m = {
				volatilidades: this.volatilidade3M?.volatilidades.map(
					(v) => v.volatilidade
				),
				labels: this.volatilidade3M?.volatilidades.map((v) =>
				v?.data.replaceAll('-','/')
				),
				rentabilidades: this.volatilidade3M?.volatilidades.map(
					(v) => v.rentabilidade_diaria
				),
				periodo: this.volatilidade3M?.volatilidades_perfil.find(
					(item) => this.volatilidade3M.periodo === item.periodo 
				) || {periodo: '3M'},
				message: this.volatilidade3M?.volatilidades_perfil[0]?.mensagem,
				estatisticas: this.volatilidade3M?.estatisticas
			}

			setTimeout(() => {
				createVolatilidadeChart(dados_1m)
				
				createVolatilidadeChart(dados_3m)
			}, 500)
		},

		async handlePeriodoVolatilidade() {
			this.periodoAtivo = this.periodoAtivo === '1M' ? '3M' : '1M'
			 GLOBAL.destroyApexChart(['xchart-volatilidade-1m', 'xchart-volatilidade-3m','chart-volatilidade-1m', 'chart-volatilidade-3m'])
			Apex._chartInstances?.map(i => {
				return i?.chart.destroy()
			} )
			
			await this.onVolatilidadeTabClick()
		},

		ondisableAtualizarVolatilidade() {
			let [day, month, year] = this.dataAtualCarteira.split('/');
			let newDate = new Date(year, month - 1, day);
			let dateFormated = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());

			let [diaInicio, mesInicio, anoInicio] = this.data_inicio.split('/');
			const dataInicio = new Date(anoInicio, mesInicio - 1, diaInicio);

			let [diaFim, mesFim, anoFim] = this.data_fim.split('/');
			const dataFim = new Date(anoFim, mesFim - 1, diaFim);

			if (dataInicio > dataFim || dataFim > dateFormated) {
				this.disableAtualizarVolatilidade = true;
			} else {
				this.disableAtualizarVolatilidade = false;
			}
		},


		async handleFilterRetorno(quantidade_meses) {
			this.filterRetornoMesAtivo =  quantidade_meses
			 GLOBAL.destroyApexChart(['xchart-retorno', 'chart-retorno'])
			Apex._chartInstances?.map(i => {
				return i?.chart.destroy()
			} )
			
			await this.onRetornoTabClick()
		},
		
		async onRetornoTabClick() {
			this.isPerfil = true
			
			this.retorno = await getDetalhesRetorno(
				this.clienteAtivo.id, this.filterRetornoMesAtivo
			)
			
			const dados = {
				cdi: this.retorno?.porcentagem_cdi?.map((cdi) => cdi.porcentagem_cdi),

				labels: this.retorno?.rentabilidades_diarias.map((v) =>
					v?.data.replaceAll('-', '/')
				),
				cotas: this.retorno?.rentabilidades_diarias.map((v) => v.valor_cota),
				rentabilidades: this.retorno?.rentabilidades_diarias.map(
					(v) => v.rentabilidade
				),
			}

			setTimeout(() => {
				createRetornoChart(dados)
			}, 500)
		},



		async onInvestimentosTabClick() {
			this.isPerfil = false

			if (this.rendaFixa) {
				this.rendaFixa = false
				this.isInvestimento = false
				this.tipo_investimento = ''
				this.ativo_table = false
				this.ativosLiquidez = false
				this.ativosRating = false
			}
			

			this.setGraficoTabInvestimentos()
		},

		onConcentracaoTabClick() {
			this.isPerfil = true

			setTimeout(() => {
				createCarteiraMarginsChart(this.graficoCarteira, this.margens)
				createCarteiraAlvosChart(this.graficoCarteira, this.margens)
			}, 500)
		},

		switchFixedIncomeOption(chartName) {
		
		this.rating_chart = false;
		this.ativo_chart = false;
		this.emissor_chart = false;
		this.tipo_chart = false;
		this.indice_chart = false;
		this.tempo_vencimento_chart = false;
		this.setor_chart = false;
		
		this.ativosRating = false

		this[chartName] = true;
		},
	
		async setTableAtivosRating(valores) {
				GLOBAL.destroyApexChart(['chart-rating'])
				this.ativosRating = true
				this.isPerfil = false
				NProgress.start()
			
				try {
					const table = document.querySelector('#table_ativos_rating')
					const bodyTable = table?.querySelector('tbody')
					const carteira = valores?.map((i) => {
						const arrItem = i[0] ? i[0] : i
						return  {
							valor: arrItem?.valor,
							tipo_investimento: arrItem?.nome,
							pct_global: arrItem?.pct_global,
							pct_renda_fixa: arrItem?.pct_tipo_investimento,
							indexador: arrItem?.indexador,
							taxa: arrItem?.taxa,
							vencimento: arrItem?.vencimento,
						}
					})
					bodyTable.innerHTML = this.templateBodyTable(carteira)
					
				} catch (e) {
					GLOBAL.errorDefault(e)
				} finally {
					NProgress.done()
				}
		},

		async getInfoRating() {
			this.rating_chart_fgc = true
			this.rating_chart_total = false
			this.rating_chart_total_fgc = false
			this.ativosRating = false
			
			this.buttonRatingAtivo = 'porcentagem_rating'
			this.switchFixedIncomeOption('rating_chart')
			const buttonRatingPorcentagem = document.querySelector('[data-rating="porcentagem_rating"]');
				if (!buttonRatingPorcentagem.classList.contains('active')) {
				const buttonsRating = document.querySelectorAll('.btn-chart-rating')
				buttonsRating?.forEach(button => {
					if (button.classList.contains('active')) {
						
						this.toggleClass(button, [
							'active',
							'secondary',
							'tertiary',
						])
					}
				})
				this.toggleClass(buttonRatingPorcentagem, ['active', 'secondary', 'tertiary'])
				
			}
			const { porcentagem_rating } = await this.getDadosInvestimentosClientesFilter('Renda Fixa')
			this.porcentagem_rating = porcentagem_rating
			setTimeout(() => {
				this.destroyAllCharts()
				
				const chart = Apex['_chartInstances']?.filter((i) => i.id == 'chart-rating')[0]
				
				if (chart) {
					chart.chart.destroy()
				}
				createRatingChart(this.porcentagem_rating)
				this.addEventClickChartRating()
			},600)

		},

		

		async voltarChartRating() {
			this.rating_chart_fgc = true
			this.rating_chart_total = false
			this.rating_chart_total_fgc = false
			this.ativosRating = false
			
			const buttonsRatingPorcentagem = document.querySelectorAll('[data-rating]');
			buttonsRatingPorcentagem?.forEach(async(buttonRating) => {
				if (buttonRating.classList.contains('active')) {
					const dataRating = buttonRating.getAttribute('data-rating')
					const dadosClienteRating = await this.getDadosInvestimentosClientesFilter('Renda Fixa')
					this.porcentagem_rating = dadosClienteRating[dataRating]

				}
			})
			
			
			setTimeout(() => {
				this.destroyAllCharts()
				
				const chart = Apex['_chartInstances']?.filter((i) => i.id == 'chart-rating')[0]
				
				if (chart) {
					chart.chart.destroy()
				}
				createRatingChart(this.porcentagem_rating)
				this.addEventClickChartRating()
			},600)

		},

		
		async voltarChartSetor() {
			
			this.ativosSetor = false
			
			
			setTimeout(() => {
				this.destroyAllCharts()
				
				const chart = Apex['_chartInstances']?.filter((i) => i.id == 'chart-setor')[0]
				
				if (chart) {
					chart.chart.destroy()
				}
				createSetorChart(this.setores)
				this.addEventClickChartSetor()
			},600)

		},

		getAtivosTipoPorcentagem(dadosClientesRating) {
			let ativos
			const t = this
			switch (t.buttonRatingAtivo) {
				case 'porcentagem_rating':
					ativos = dadosClientesRating.composicao_investimentos
					break
				case 'porcentagem_rating_fgc':
					ativos = dadosClientesRating.grafico_fgc
					break
				case 'porcentagem_rating_fgc_menos_total':
					ativos = dadosClientesRating.grafico_composicao_investimentos_menos_fgc
					break
				default:
					ativos = []
			}
			return ativos
		},

		addEventClickChartRating() {
			const t = this
			const chart = Apex._chartInstances?.filter((i) => i.id == 'chart-rating')?.[0]
			if (chart) {
				chart.chart.updateOptions({
					chart: {
						events: {
							click: async function (event, chartContext, config) {
								const key = config?.globals?.labels[config?.dataPointIndex]
								
								if (!key) return
								t.periodo_ativo_rating = key
								const dadosClientesRating = await t.getDadosInvestimentosClientesFilter('Renda Fixa', `&rating=${key}`)
								const listAtivos = t.getAtivosTipoPorcentagem(dadosClientesRating)
								
								const valores = Object.values(listAtivos)
								t.setTableAtivosRating(valores)
							},
						},
					},
				})
			}
		},

		async alternaVisualizacaoChartRating(e, tipoRating) {
			this.ativosRating = false
			const el = e?.target
			const dataRating = el.getAttribute('data-rating')
			if (!el.classList.contains('active')) {
				const buttonsRating = document.querySelectorAll('.btn-chart-rating')
				buttonsRating?.forEach(button => {
					if (button.classList.contains('active')) {
						
						this.toggleClass(button, [
							'active',
							'secondary',
							'tertiary',
						])
					}
				})
				this.toggleClass(el, ['active', 'secondary', 'tertiary'])
				
			}
			const dadosClienteRating = await this.getDadosInvestimentosClientesFilter(tipoRating)
			if (dataRating) {
				this.buttonRatingAtivo = dataRating
				this.porcentagem_rating = dadosClienteRating[dataRating]
			}
			setTimeout(() => {
				this.destroyAllCharts()
				createRatingChart(this.porcentagem_rating)
				this.addEventClickChartRating()
			}, 600)
		},

		async getInfoModalEmissor() {
			
			this.switchFixedIncomeOption('emissor_chart')
			
			await this.getDadosItensCarteira()
			this.chart_setor = false
			await this.getInfoChartFiltro(
				'#chart-emissor',
				'chartEmissor',
				this.emissores,
				'emissores',
				true
			)
		},

		
		alternaEstiloButtonChartEmissores(e) {
			const el = e.target
			let classOutroBtn = ['.emissores_fgc', '.emissores_setor']
			
			if (el.classList.contains('emissores_todos')) {
				classOutroBtn = ['.emissores_fgc', '.emissores_setor']
			
			} else if (el.classList.contains('emissores_fgc')) {
				classOutroBtn = ['.emissores_todos', '.emissores_setor']	
			}else if (el.classList.contains('emissores_setor')) {
				classOutroBtn = ['.emissores_todos', '.emissores_fgc']	
			}
			if (!el.classList.contains('active')) {
				this.toggleClass(el, ['active', 'secondary', 'tertiary'])
				if (classOutroBtn) {
					classOutroBtn.forEach(el => {
						document.querySelector(el).classList.remove('active', 'secondary')
						document.querySelector(el).classList.add('tertiary')
					
					})
					
				}
			}
		},

		async setTableAtivosSetor(valores) {
				GLOBAL.destroyApexChart(['chart-setor'])
				this.ativosSetor = true
				this.isPerfil = false
				NProgress.start()
			
				try {
					const table = document.querySelector('#table_ativos_setor')
					const bodyTable = table?.querySelector('tbody')
					console.log({valores})
					const rowTable = valores?.map((i) => {
						const arrItem = i[0] ? i[0] : i
						return  {
							valor: arrItem?.valor,
							tipo_investimento: arrItem?.nome,
							pct_global: arrItem?.pct_global,
							pct_renda_fixa: arrItem?.pct_tipo_investimento,
							indexador: arrItem?.indexador,
							taxa: arrItem?.taxa,
							vencimento: arrItem?.vencimento,
						}
					})
					bodyTable.innerHTML = this.templateBodyTable(rowTable)
					
				} catch (e) {
					GLOBAL.errorDefault(e)
				} finally {
					NProgress.done()
				}
		},

	addEventClickChartSetor() {
			const t = this
			const chart = Apex._chartInstances?.filter((i) => i.id == 'chart-setor')?.[0]
			if (chart) {
				chart.chart.updateOptions({
					chart: {
						events: {
							click: async function (event, chartContext, config) {
								const key = config?.globals?.labels[config?.dataPointIndex]
								
								if (!key) return
								t.periodo_ativo_setor = key
								const dadosClientesRating = await t.getDadosInvestimentosClientesFilter('Renda Fixa', `&setor=${key}`)
								const listAtivos = dadosClientesRating?.ativos_grafico_setor
								
								const valores = Object.values(listAtivos)
								t.setTableAtivosSetor(valores)
							},
						},
					},
				})
			}
		},
		async getInfoModalSetor() {
			this.destroyAllCharts()
			const chart = Apex['_chartInstances']?.filter((i) => i.id == 'chart-setor')[0]
			if (chart) {
				chart.chart.destroy()
			}
			
			this.switchFixedIncomeOption('setor_chart')
			await this.getDadosItensCarteira()
			
			createSetorChart(this.setores)
			this.addEventClickChartSetor()

		},

		async alternaVisualizacaoChartEmissores(e) {
			const id = e.target.id
			console.log({id})
			const verTodos = id === 'emissores'
			
			
			if (id && id !== 'setores') {
				this.emissor_chart = true
				this.chart_setor = false
				this.destroyAllCharts()
				setTimeout(async () => {
					this.destroyAllCharts()
					
					const chart = Apex['_chartInstances']?.filter((i) => i.id == 'chartEmissor')[0]
					
					if (chart) {
						chart.chart.destroy()
					}
					//this.addEventClickChartRating()
					await this.getInfoChartFiltro(
						'#chart-emissor',
						'chartEmissor',
						this[id],
						'emissores',
						verTodos
					)
			},600)
			} else {
				this.chart_setor = true
				this.getInfoModalSetor()
			}
			this.alternaEstiloButtonChartEmissores(e)
		},

		setChartBarraFiltro(idElement, idChart, itens, key, verTodos = false) {
			const chart = Apex._chartInstances?.filter((i) => i.id == idChart)[0]
			
			if (chart) {
				chart.chart.destroy()
			}

			if (GLOBAL.isString(itens)) {
				chart?.chart.destroy()
				this.chartError = itens
				return
			} else {
				this.chartError = null
			}

			let seriesValue = []
			let labels = []
			let valores = []
			if (itens) {
				if (key === 'emissores' && verTodos) {
					valores = Object.values(itens[key])
					seriesValue = valores.map(valor => valor.Total)
				} else if (key === 'emissores' && !verTodos) {
					valores = Object.values(itens[key])
					seriesValue = valores.map(valor => valor.Total_fgc)
				}		
				else{
					seriesValue = Object.values(itens[key])
				}
				labels = Object.keys(itens[key])
			}
			let seriesInfo = []
			itens?.info.forEach((i) => {
				seriesInfo.push(i.valor)
			})

			labels = labels?.map((label) => {
				return label || 'OUTROS'
			})

			let valorLinha = 0

			if (key === 'emissores') {
				valorLinha = 250000.0
			} else if (key === 'tipos') {
				valorLinha = verTodos
					? 250000.0
					: 1000000.0 - this.total_eventos_de_credito
			}

			const annotations = {
				yaxis: [
					{
						y: valorLinha,
						strokeDashArray: 3,
						borderColor: '#6D6F71',
						label: {
							show: false,
							borderColor: '#775DD0',
							style: {
								color: '#fff',
								background: '#775DD0',
							},
							text: undefined,
						},
					},
				],
			}

			const legend =
				key === 'tipos' || key === 'emissores'
					? {
							show: true,
							markers: {
								width: 20,
								height: 4,

								strokeColor: '#fff',

								radius: 8,
							},
							itemMargin: {
								horizontal: 8,
								vertical: 24,
							},
					  }
					: { show: false }

					console.log({ seriesInfo, seriesValue })
					new ApexCharts(document.querySelector(idElement), {
						chart: {
							id: idChart,
							type: 'bar',
							height: 450,
							// width: labels.length > 12
							// ? `${labels.length * 150}px`
							// : '100%',
							stacked: true,
							toolbar: {
								show: false,
							},
							zoom: {
								enabled: true,
							},
						},

						colors: ['#c11f39', '#7B0100'],
						labels: labels,
						annotations: annotations,
						series: [
							{
								name: 'Valor presente',
								data: seriesValue,
							},
							{
								name: 'Rendimento até vencimento',
								data: seriesInfo,
							},
						],
						legend: legend,
						tooltip: {
							enabled: true,

							shared: true,
							intersect: false,
							custom: (seriesName) => {
								const currentValue = seriesName.series[seriesName.seriesIndex]
								const valorEmissor = key === 'emissores' && verTodos
								const currentIndex = seriesName.dataPointIndex
								const isFGC =
									this.emissores_fgc?.emissores[labels[currentIndex]]
								const porcentagem = valorEmissor
									? `<p class="text-neutral-70 font-normal text-xs">Percentual do PL: ${valores[currentIndex].Percentual}%</p>`
									: ''
								const porcentagemFgc =
									key === 'emissores' && !verTodos
										? `<p class="text-neutral-70 font-normal text-xs">Percentual do PL: ${valores[currentIndex].Percentual_fgc}%</p>`
										: ''
								const diffFGCValue =
									itens?.info[currentIndex].dif_fgc == '-'
										? 'Não encontrado'
										: GLOBAL.formatReal2(itens?.info[currentIndex].dif_fgc)
								const diffFGC = isFGC
									? `
					<p class="text-neutral-70 font-normal text-xs">Dif. FGC: ${diffFGCValue}</p>`
									: ''

								return `<div class="p-12 bg-white">
								<p class="font-medium text-sm text-neutral-100">${labels[currentIndex]}</p>
								<p class="text-neutral-70 font-normal text-xs">${GLOBAL.formatReal2(
									currentValue[currentIndex]
								)}</p>
								${porcentagem || porcentagemFgc}
								<p class="text-neutral-70 font-normal text-xs">Rentabilizado: ${
									seriesInfo[currentIndex] == '-'
										? 'Não encontrado'
										: GLOBAL.formatReal2(seriesInfo[currentIndex])
								}</p>
								${diffFGC}
							</div>`
							},
						},

						yaxis: {
							show: true,
							showAlways: true,
							labels: {
								formatter: function (val, index) {
									let formatter = Intl.NumberFormat('en', {
										notation: 'compact',
									})
									return ` ${formatter.format(val)}`
								},
							},
						},

						xaxis: {
							type: 'category',
							categories: labels,
							labels: {
								show: true,
								formatter: (val) => {
									let str = val.length > 10 ? val.substring(0, 10) + '...' : val
									return str || 'OUTROS'
								},
							},
							style: {
								colors: ['#01071B'],
								fontSize: '10px',
								fontFamily: 'Helvetica, Arial, sans-serif',
								fontWeight: 400,
								cssClass: 'apexcharts-xaxis-label',
							},
						},
						dataLabels: {
							enabled: true,
							formatter: function (val, opt) {
								return ''
							},
						},
					}).render()
		},

		async setGraficoTabInvestimentos(info /* dados dos investimentos */) {
			await this.getDadosUpdateModal()
			const t = this
			
			const idChart = '#chart-tab-divisao-investimento'

			GLOBAL.destroyApexChart('chartTabInvestimentos')

			if (GLOBAL.isString(this.graficoCarteira)) {
				//coloca a mensagem que o back manda se a resposta for string
				this.chartError = this.graficoCarteira
				return
			} else {
				this.chartError = null
			}

			const graficoCarteiraOrdered = GLOBAL.mergeSortAnArrayWithObjects(
				this.graficoCarteira?.map((i) => ({
					...i,
					porcentagem: Number(i.porcentagem),
				})),
				'porcentagem',
				true
			)

			const series = graficoCarteiraOrdered.map((i) => i.porcentagem)
			const valores = graficoCarteiraOrdered.map((i) => Number(i.valor))
			const labels = graficoCarteiraOrdered.map((i) => i.tipo_investimento)

			const colors = this.defineColors(labels)

			if (info) this.infoGraficoModal = info.name
			else this.infoGraficoModal = null

			document.querySelector(idChart).innerHTML = ''

			new ApexCharts(document.querySelector(idChart), {
				...optionsApexAtendimento,
				chart: {
					fontFamily: 'Roboto Flex, sans-serif',
					id: 'chartTabInvestimentos',
					width: '100%',
					height: 450,
					type: 'donut',
					events: {
						click: function (event, chartContext, config) {
							if (t.isFundacao) return
							if (t.infoGraficoModal) return

							let posSelected = config.globals?.selectedDataPoints[0]
							let labels = config.globals?.labels
							const nameSelected = labels[posSelected]
							if (!nameSelected) return
							t.getInfoModalClienteTabInvestimentos(nameSelected)
						},
					},
				},

				colors: colors,
				tooltip: {
					enabled: true,
					x: {
						formatter: function (value, opts) {
							return value
						},
					},
					y: {
						formatter: function (value, opts) {
							const index = opts.seriesIndex
							const sum = valores.reduce((a, b) => a + b, 0)
							const percent = (valores / sum) * 100
							return `${GLOBAL.formatReal2(valores[index])} (${value.toFixed(
								2
							)}%)`
						},
					},
				},
				legend: {
					show: true,
					showForSingleSeries: false,
					showForNullSeries: true,
					showForZeroSeries: true,
					position: 'bottom',
					horizontalAlign: 'center',
					verticalAlign: 'center',
					floating: false,
					fontSize: '0.88rem',
					fontFamily: 'Roboto Flex, sans-serif',
					fontWeight: 400,
					inverseOrder: false,
					offsetX: 0,
					offsetY: 0,

					markers: {
						// width: 12,
						// height: 12,
						strokeWidth: 0,
						strokeColor: '#fff',
						radius: 12,
					},
				},
				series: series,
				labels: labels,
			}).render()

			document
				.querySelectorAll(
					'#chart-tab-divisao-investimento .apexcharts-datalabels'
				)
				.forEach((label, index) => {
					const rect = label.querySelector('rect')
					const text = label.querySelector('text').textContent
					const i = labels.findIndex((label) => text.startsWith(label))
				
					const x = parseInt(Number(rect.getAttribute('x'))) + 11
					const y = parseInt(Number(rect.getAttribute('y'))) + 15
					rect.insertAdjacentHTML(
						'afterend',
						`<circle cx="${x}" cy="${y}" r="6" fill="${colorsChatAtendimento[i]}"/>`
					)
				})
		},

		//Grafico por emissor

		async getInfoChartFiltro(idElement, idChart, itens, key, verTodos) {
			NProgress.start()
			try {
				this.setChartBarraFiltro(idElement, idChart, itens, key, verTodos)
			} catch (e) {
				GLOBAL.errorDefault(e)
			} finally {
				NProgress.done()
			}
		},

		//Grafico por tipo
		async getInfoModalTipo() {
			this.switchFixedIncomeOption('tipo_chart')
			

			await this.getDadosItensCarteira()

			await this.getInfoChartFiltro(
				'#chart-tipo',
				'chartTipo',
				this.tipos,
				'tipos',
				true
			)
		},

		async getInfoModalIndice() {
			this.switchFixedIncomeOption('indice_chart')
			
			NProgress.start()

			try {
				await this.getDadosItensCarteira()
				this.setGraficoIndice()
			} catch (e) {
				GLOBAL.errorDefault(e)
			} finally {
				NProgress.done()
			}
		},

		async getInfoModalTempoVencimento() {
			
			this.switchFixedIncomeOption('tempo_vencimento_chart')
			this.tempo_vencimento = await getDadosTempoVencimento(
				this.clienteAtivo.id
			)
			createTempoVencimentoCharts(this.tempo_vencimento)
		},

		async getInfoModalClienteTabInvestimentos(nameSelected) {
			this.isInvestimento = true
			this.tipo_investimento = nameSelected
			this.ativosTableSortKey = ''
			this.ativosTableSortOrderInDesc = false

			if (nameSelected !== 'Renda Fixa') {
				this.rendaFixa = false
				await this.clickItensInvestimentos(nameSelected)
			} else {
				this.rendaFixa = true
				this.ativo_chart = true
				this.ativo_table = true

				NProgress.start()

				try {
					const results = await this.getDadosInvestimentosClientes(nameSelected)
					const table = document.querySelector('#table_ativos')
					const bodyTable = table.querySelector('tbody')

					const carteira = results?.map((i) => ({
						valor: i[0].valor,
						tipo_investimento: i[0].nome,
						pct_global: i[0].pct_global,
						pct_renda_fixa: i[0].pct_tipo_investimento,
						indexador: i[0].indexador,
						taxa: i[0].taxa,
						vencimento: i[0].vencimento,
					}))
					bodyTable.innerHTML = this.templateBodyTable(carteira)
					const chartAtendimento = Apex._chartInstances?.filter(
						(i) => i.id == 'chartTabInvestimentos'
					)[0]
					chartAtendimento?.chart.destroy()
					GLOBAL.destroyApexChart(['chartTabInvestimentos'])
					this.setGraficoAtivo({
						name: nameSelected,
					})
				} catch (e) {
					GLOBAL.errorDefault(e)
				} finally {
					NProgress.done()
				}
			}
		},

		async getInfoDetalhesVolatilidade(periodo, data_inicio, data_fim) {
			
			const resultVolatilidade = await getDetalhesVolatilidade(
				this.clienteAtivo.id,
				periodo,
				data_inicio,
				data_fim
			)
			return resultVolatilidade
		},

		

		async getInfoModalCliente(nameSelected, rating= '') {
			NProgress.start()
			try {
			
				const { data } = await axios.get(
					`${window.location.origin}/atendimento/grafico_composicao_cliente_pf/?cliente_pf=${this.clienteAtivo.id}&tipo_investimento=${nameSelected}&rating=${rating}`
				)
				console.log({data})
				this.porcentagem_rating = data?.porcentagem_rating
				if(!data?.composicao_investimentos) return
				
				const composisaoInvestimentos = Object.values(data?.composicao_investimentos)?.flat()
				
				this.graficoCarteira = composisaoInvestimentos?.map((i) => ({
					valor: i.valor,
					tipo_investimento: i.nome,
					porcentagem: i.taxa,
				}))


				const chartAtendimento = Apex._chartInstances?.filter(
					(i) => i.id == 'chartIndice'
				)[0]

				chartAtendimento &&  chartAtendimento.chart.destroy()

				this.setGraficoCarteira({
					name: nameSelected,
				})
			} catch (e) {
				GLOBAL.errorDefault(e)
			} finally {
				NProgress.done()
			}
		},

		setGraficoCarteira(info /* dados dos investimentos */) {
			const t = this
			const idChart = t.isFundacao
				? '#chart-divisao-investimento'
				: '#chart-divisao-investimento-pf'
			const id = t.isFundacao ? 'chartAtendimento' : 'chartCarteira'
			
			GLOBAL.destroyApexChart(id)

			if (GLOBAL.isString(this.graficoCarteira)) {
				//coloca a mensagem que o back manda se a resposta for string
				chartAtendimento?.chart.destroy()
				this.chartError = this.graficoCarteira
				return
			} else {
				this.chartError = null
			}

			const graficoCarteiraOrdered = GLOBAL.mergeSortAnArrayWithObjects(
				this.graficoCarteira?.map((i) => ({
					...i,
					porcentagem: Number(i.porcentagem),
				})),
				'porcentagem',
				true
			)

			const valores = graficoCarteiraOrdered.map((i) => Number(i.valor))
			const porcentagens = graficoCarteiraOrdered.map((i) => Number(i.porcentagem))
			let labels
			if(t.isFundacao){
				labels = graficoCarteiraOrdered.map((i) => i.estrategia)
			}else{
				labels = graficoCarteiraOrdered.map((i) => i.tipo_investimento)
			}
			const series = graficoCarteiraOrdered.map((i) => Number(i.valor))
			
			if (info) this.infoGraficoModal = info.name
			else this.infoGraficoModal = null
			
			new ApexCharts(document.querySelector(idChart), {
				...optionsApexAtendimento,
				chart: {
					fontFamily: 'Roboto Flex, sans-serif',
					id: id,
					width: '100%',
					height: '380px',
					type: 'donut',
					events: {
						click: function (event, chartContext, config) {
							if (t.isFundacao) return
							if (t.infoGraficoModal) return

							let posSelected = config.globals?.selectedDataPoints[0]
							let labels = config.globals?.labels
							const nameSelected = labels[posSelected]
							if (!nameSelected) return
							t.getInfoModalCliente(nameSelected)
						},
					},
				},
				title: {
					text: 'Carteira Atual',
					align: 'center',
					margin: 0,
					offsetX: 0,
					offsetY: 0,
					floating: false,
					style: {
						fontSize: '16px',
						fontWeight: 'normal',
						fontFamily: undefined,
						color: '#263238',
					},
				},
				
				tooltip: {
					enabled: true,
					x: {
						formatter: function (value, opts) {
							return `${value}%`
						},
					},
					y: {
						formatter: function (value) {
							const sum = valores?.reduce((a, b) => a + b, 0)
							const percent = (value / sum) * 100
							return `${GLOBAL.formatReal2(value)} (${percent?.toFixed(2)}%)`
						},
					},
				},
				legend: {
					show: true,
					showForSingleSeries: false,
					showForNullSeries: true,
					showForZeroSeries: true,
					position: 'bottom',
					horizontalAlign: 'center',
					verticalAlign: 'center',
					floating: false,
					fontSize: '0.88rem',
					fontFamily: 'Roboto Flex, sans-serif',
					fontWeight: 400,
					inverseOrder: false,

					offsetX: 0,
					offsetY: 0,

					markers: {
						strokeWidth: 0,
						strokeColor: '#fff',
						radius: 12,
					},
				},
				series: series,
				labels: labels,
			}).render()

			document
				.querySelectorAll(
					'#chart-divisao-investimento-pf .apexcharts-datalabels'
				)
				.forEach((label, index) => {
					const rect = label.querySelector('rect')
					const text = label.querySelector('text').textContent
					const i = labels.findIndex((label) => text.startsWith(label))
					const x = parseInt(Number(rect.getAttribute('x'))) + 11
					const y = parseInt(Number(rect.getAttribute('y'))) + 15
					rect.insertAdjacentHTML(
						'afterend',
						`<circle cx="${x}" cy="${y}" r="6" fill="${colorsChatAtendimento[i]}"/>`
					)
				})

			this.divisao_chart = true
		},

		setGraficoIndice(info) {
			const t = this
			const idChart = '#chart-indice'

			this.divisao_chart = false

			GLOBAL.destroyApexChart(['chartCarteira', 'chartIndice'])

			if (GLOBAL.isString(this.indices)) {
				//coloca a mensagem que o back manda se a resposta for string
				chartIndice?.chart.destroy()
				this.chartError = this.indices
				return
			} else {
				this.chartError = null
			}
			let labels = Object.keys(this.indices)

			labels = labels.map((label) =>
				label === '' || label === 'null' ? 'OUTROS' : label
			)

			const values = Object.values(this.indices)
			const series = values.map((item) => item.valor)

			if (info) this.infoGraficoModal = info.name
			else this.infoGraficoModal = null

			new ApexCharts(document.querySelector(idChart), {
				...optionsApexAtendimento,
				chart: {
					fontFamily: 'Roboto Flex, sans-serif',
					id: 'chartIndice',
					width: '100%',
					height: 450,
					type: 'donut',
					events: {
						click: function (event, chartContext, config) {
							if (t.isFundacao) return
							if (t.infoGraficoModal) return

							let posSelected = config.globals?.selectedDataPoints[0]
							let labels = config.globals?.labels
							const nameSelected = labels[posSelected]
							if (!nameSelected) return
							t.getInfoModalCliente(nameSelected)
						},
					},
				},

				tooltip: {
					enabled: true,
					x: {
						formatter: function (value, opts) {
							return value
						},
					},
					y: {
						formatter: function (value, opts) {
							return `${GLOBAL.formatReal2(value)} (${
								values[opts.seriesIndex].porcentagem
							}%)`
						},
					},
				},

				legend: {
					show: true,
					showForSingleSeries: false,
					showForNullSeries: true,
					showForZeroSeries: true,
					position: 'bottom',
					horizontalAlign: 'center',
					verticalAlign: 'center',
					floating: false,
					fontSize: '0.75rem',
					fontFamily: 'Roboto Flex, sans-serif',
					fontWeight: 400,
					inverseOrder: false,
					width: '100%',
					offsetX: 0,
					offsetY: 0,

					markers: {
						// width: 12,
						// height: 12,
						strokeWidth: 0,
						strokeColor: '#fff',
						radius: 12,
					},
				},
				series: series,
				labels: labels,
			}).render()
			document
				.querySelectorAll('#chart-indice .apexcharts-datalabels')
				.forEach((label, index) => {
					const rect = label.querySelector('rect')
					const text = label.querySelector('text').textContent
					const i = labels.findIndex((label) => text.startsWith(label))
					const x = parseInt(Number(rect.getAttribute('x'))) + 11
					const y = parseInt(Number(rect.getAttribute('y'))) + 15
					rect.insertAdjacentHTML(
						'afterend',
						`<circle cx="${x}" cy="${y}" r="6" fill="${colorsChatAtendimento[i]}"/>`
					)
				})
		},

		setGraficoAtivo(info /* dados dos investimentos */) {
			const idChart = '#chart-ativo'
			const chartAtivo = Apex._chartInstances?.filter(
				(i) => i.id === 'chartAtivo'
			)[0]

			GLOBAL.destroyApexChart(['chartTabInvestimentos'])

			if (chartAtivo) {
				chartAtivo.chart.destroy()
			}

			if (GLOBAL.isString(this.graficoCarteira)) {
				//coloca a mensagem que o back manda se a resposta for string
				chartAtivo?.chart.destroy()
				this.chartError = this.graficoCarteira
				return
			} else {
				this.chartError = null
			}

			const series = this.graficoCarteira?.map((i) => Number(i.valor)) || []

			let keyPct = ''
			if (this.rendaFixa) {
				keyPct = 'pct_renda_fixa'
			} else {
				keyPct = 'pct_tipo_investimento'
			}
			const porcentagens = this.graficoCarteira?.map((i) => {
				return Number(i[keyPct])
			})
			const labels = this.graficoCarteira?.map((i) => i.tipo_investimento) || []

			if (info) this.infoGraficoModal = info.name
			else this.infoGraficoModal = null

			new ApexCharts(document.querySelector(idChart), {
				...optionsApexAtendimento,
				chart: {
					fontFamily: 'Roboto Flex, sans-serif',
					id: 'chartAtivo',
					width: '100%',
					height: '380px',
					type: 'donut',
				},

				legend: {
					show: true,
					showForSingleSeries: false,
					showForNullSeries: true,
					showForZeroSeries: true,
					position: 'bottom',
					horizontalAlign: 'center',
					verticalAlign: 'center',
					floating: false,
					fontSize: '0.88rem',
					fontFamily: 'Roboto Flex, sans-serif',
					fontWeight: 400,
					inverseOrder: false,
					offsetX: 0,
					offsetY: 0,

					markers: {
						strokeWidth: 0,
						strokeColor: '#fff',
						radius: 12,
					},
				},
				tooltip: {
					y: {
						formatter: function (value, opts) {
							const index = opts.seriesIndex
							const sum = opts.config.series.reduce((a, b) => a + b, 0)
							return `${GLOBAL.formatReal2(value)} (${porcentagens[
								index
							].toFixed(2)}%) `
						},
					},
				},
				series: series,
				labels: labels,
			}).render()

			document
				.querySelectorAll('#chart-ativo .apexcharts-datalabels')
				.forEach((label, index) => {
					const rect = label.querySelector('rect')
					const text = label.querySelector('text').textContent
					const i = labels.findIndex((label) => text.startsWith(label))
					const x = parseInt(Number(rect.getAttribute('x'))) + 11
					const y = parseInt(Number(rect.getAttribute('y'))) + 15
					rect.insertAdjacentHTML(
						'afterend',
						`<circle cx="${x}" cy="${y}" r="6" fill="${colorsChatAtendimento[i]}"/>`
					)
				})
		},

		async setGraficoPerfilInvestidor(info /* dados dos investimentos */) {
			const t = this
			const idChart = '#chart-divisao-carteira-investimentos'

			const ChartInvestimento = Apex._chartInstances?.filter(
				(i) => i.id === 'ChartInvestimento'
			)[0]

			if (ChartInvestimento) ChartInvestimento.chart.destroy()
		
			if (GLOBAL.isString(this.graficoCarteira)) {
				//coloca a mensagem que o back manda se a resposta for string
				ChartInvestimento?.chart.destroy()
				this.chartError = this.graficoCarteira
				return
			} else {
				this.chartError = null
			}
			if (this.clienteAtivo.perfil_investidor_id) {
				await this.getPerfilInvestidor(this.clienteAtivo.perfil_investidor_id)
			} else {
				this.perfilInvestidor = []
			}

			const series = this.perfilInvestidor?.item_perfil_investidor_read?.map(
				(i) => Number(i.alvo)
			)
			const labels = this.perfilInvestidor?.item_perfil_investidor_read?.map(
				(i) => i.tipo_investimento
			)

			if (info) this.infoGraficoModal = info.name
			else this.infoGraficoModal = null
			const colors = this.defineColors(labels)
			new ApexCharts(document.querySelector(idChart), {
				...optionsApexAtendimento,
				chart: {
					fontFamily: 'Roboto Flex, sans-serif',
					id: 'chartInvestimento',
					width: '100%',
					height: '380px',
					type: 'donut',
					events: {
						click: function (event, chartContext, config) {
							if (t.isFundacao) return
							if (t.infoGraficoModal) return

							let posSelected = config.globals?.selectedDataPoints[0]
							let labels = config.globals?.labels
							const nameSelected = labels[posSelected]
							if (!nameSelected) return
							t.getInfoModalCliente(nameSelected)
						},
					},
				},
				colors: colors,
				tooltip: {
					enabled: true,
					x: {
						formatter: function (value) {
							return `${value}%`
						},
					},
					y: {
						formatter: function (value, opts) {
							return `${value.toFixed(1)}%`
						},
					},
				},
				title: {
					text: `${this.perfilInvestidor.nome}`,
					align: 'center',
					margin: 0,
					offsetX: 0,
					offsetY: 0,
					floating: false,
					style: {
						fontSize: '16px',
						fontWeight: 'normal',
						fontFamily: undefined,
						color: '#263238',
					},
				},
				legend: {
					show: true,
					showForSingleSeries: false,
					showForNullSeries: true,
					showForZeroSeries: true,
					position: 'bottom',
					horizontalAlign: 'center',
					verticalAlign: 'center',
					floating: false,
					fontSize: '0.75rem',
					fontFamily: 'Roboto Flex, sans-serif',
					fontWeight: 400,
					inverseOrder: false,
					width: '100%',
					offsetX: 0,
					offsetY: 0,

					markers: {
						strokeWidth: 0,
						strokeColor: '#fff',
						radius: 12,
					},
				},
				series: series,
				labels: labels,
			}).render()

			document
				.querySelectorAll(
					'#chart-divisao-carteira-investimentos .apexcharts-datalabels'
				)
				.forEach((label, index) => {
					const rect = label.querySelector('rect')
					const text = label.querySelector('text').textContent
					const i = labels.findIndex((label) => text.startsWith(label))
					const x = parseInt(Number(rect.getAttribute('x'))) + 11
					const y = parseInt(Number(rect.getAttribute('y'))) + 15
					rect.insertAdjacentHTML(
						'afterend',
						`<circle cx="${x}" cy="${y}" r="6" fill="${colorsChatAtendimento[i]}"/>`
					)
				})
		},

		resetButtonsFilters() {
			const btns = document.querySelectorAll('.btn-chart-filter')

			
			this.switchFixedIncomeOption('')

			btns.forEach((btn) => {
				if (btn.classList.contains('ativo-inicial')) {
					!btn.classList.contains('active') &&
						this.toggleClass(btn, ['active', 'secondary', 'tertiary'])
				} else {
					btn.classList.add('tertiary')
					btn.classList.remove(...['secondary', 'active'])
				}
			})
		},

		destroyAllCharts() {
			GLOBAL.destroyApexChart([
				'chartCarteira',
				'chartTabInvestimentos',
				'chartInvestimento',
				'chartAtivo',
				'chartEmissor',
				'chartTipo',
				'chartIndice',
				'chartTempoVencimentoIPCA',
				'chartTempoVencimentoPos',
				'chartTempoVencimentoPre',
				'chartCarteiraMargens',
				'chart-volatilidade-1m',
				'chart-volatilidade-3m',
				'chart-retorno',
				'chart-rating',
			])
		},

		setDates(){
			let [day, month, year] = this.dataAtualCarteira.split('/');
			let newDate = new Date(year, month - 1, day)
			let dataInicio = Quasar.date.subtractFromDate(newDate, { days: 90 })
			
			this.data_inicio = Quasar.date.formatDate(dataInicio, 'DD/MM/YYYY')
			this.data_fim =  Quasar.date.formatDate(newDate,  'DD/MM/YYYY')
		}
	},
	mounted() {
		
		
	}
}
