import { Grid, html as htmlGrid } from 'gridjs'
const { getChoicesActive } = GLOBAL

const politicaOpcoes = {
	'0': 'ULTRA HIGH',
	'1': 'PRIVATE',
	'2': 'PREMIUM',
	'3': 'DISTRIBUIÇÃO'
}

const politicaOpcoesCores = {
	'0': '#840505',
	'1': '#d83232',
	'2': '#e84f68b3',
	'3': '#6d6f71'
}

const dataGastosVazio = [
	{
		name: "ULTRA HIGH",
		color: '#840505',
		data: []
	},
	{
		name: "PRIVATE",
		color: '#d83232',
		data: []
	},
	{
		name: "PREMIUM",
		color: '#e84f68b3',
		data: []
	},
	{
		name: "DISTRIBUIÇÃO",
		color: '#6d6f71',
		data: []
	}
]

let captacaoXLabel = []

// const selectSemestres = GLOBAL.getChoicesActive('semestre', window.listOfChoices)

export default {
	emits: [],

	props: {},

	data(){
		return {
			filterCaptacao: null,
			filterUsuario: null,
			chartCaptacao: null,
			chartGastos: null,
			dataCaptacao: null,
			mediaRentabilidade: null,
			todosUsuarios: [],
			dataGastos: [
				{
					name: "ULTRA HIGH",
					color: '#840505',
					data: []
				},
				{
					name: "PRIVATE",
					color: '#d83232',
					data: []
				},
				{
					name: "PREMIUM",
					color: '#e84f68b3',
					data: []
				},
				{
					name: "DISTRIBUIÇÃO",
					color: '#6d6f71',
					data: []
				}
			],
			optionsGastos: {
				chart: {
					height: 400,
					width: '100%',
					type: 'scatter',
				},
				series: [],
				yaxis: {
					type: 'numeric',
					labels: {
						formatter: function (value) {
							return `${value.toFixed(2).replace('.', ',')}%`;
						},
					},
					title: {
						text: 'Rentabilidade (%)',
					},
					// min: -50, // Ajusta o início do eixo X
					// max: 50,  // Ajusta o final do eixo X
				},
				xaxis: {
					labels: {
						formatter: function (value) {
							const money = new Intl.NumberFormat('pt-BR', {
								style: 'currency',
								currency: 'BRL',
							}).format(value)
							return money;
						},
					},
					title: {
						text: 'Patrimônio (R$)',
					},
					min: 0, // Ajusta o início do eixo Y
					max: 13000000,  // Ajusta o final do eixo Y
					tickAmount: 10
				},
				annotations: {
					xaxis: [
						{
							x: 0, // Linha central no eixo Y
							borderColor: '#000',
							strokeDashArray: 5,
							label: {
								text: '',
								position: 'right',
								orientation: 'horizontal',
							},
						},
					],
					yaxis: [
						{
							y: 0, // Linha central no eixo X
							borderColor: '#000',
							strokeDashArray: 5,
							label: {
								text: '',
								position: 'right'
							},
						},
					],
				},
				tooltip: {
					custom: function ({ seriesIndex, dataPointIndex, w }) {
						const point = w.config.series[seriesIndex].data[dataPointIndex];
						return `
							<div class="apexcharts-custom-tooltip" style="border-color: ${point.color};">
								<div>
									<span class="dot" style="background: ${point.color};"></span>
									<strong>${point.label}</strong>
								</div>
								<div><strong>Rentabilidade: </strong> <span> ${point.y.toFixed(2).replace('.', ',')}%</span></div>
								<div><strong>Volatilidade 1M: </strong> <span> ${point.volatilidade_media_1M.toFixed(2).replace('.', ',')}%</span></div>
								<div><strong>Volatilidade 3M: </strong> <span> ${point.volatilidade_media_3M.toFixed(2).replace('.', ',')}%</span></div>
								<div><strong>% CDI 12 meses: </strong> <span> ${point.rentabilidade_bm_12_meses.toFixed(2).replace('.', ',')}%</span></div>
								<div><strong>% CDI ano: </strong> <span>${point.rentabilidade_bm_ano.toFixed(2).replace('.', ',')}%</span> </div>
							</div>
						`;
					},
				}
			},
			optionsCaptacao: {
				series: [],
				chart: {
					height: 350,
					type: 'line',
					zoom: {
						enabled: false
					},
					toolbar: {
						show: false,
					}
				},
				colors: ['#949799', '#C11F39'],
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'straight',
					width: 2
				},
				markers: {
					size: 4,
				},
				title: {
					text: undefined,
					align: 'left'
				},
				grid: {
					show: true,
					borderColor: '#EDEEF0',
					xaxis: {
						lines: {
							show: true,
						},
					},
					yaxis: {
						lines: {
							show: true,
						},
					},
		
				},
				xaxis: {
					labels: {
						show: true,
						maxHeight: 50,
						styles: {
							cssClass: 'apexcharts-xaxis-label'
						},
						formatter: function (value) {
							// Exibe a label personalizada ou o valor padrão
							console.log(captacaoXLabel[value])
							return value ? captacaoXLabel[value] : value
						},
	
					},
				},
				yaxis: {
					labels: {
						show: true,
						styles: {		
							cssClass: 'apexcharts-yaxis-label',
						},
						formatter: function (value) {
							const money = new Intl.NumberFormat('pt-BR', {
								style: 'currency',
								currency: 'BRL',
							}).format(value)
							return money;
						},
					}
				},
				legend: {
					fontFamily: 'Roboto Flex',
					fontWeight: 500,
					labels: {
						colors: ['#252526'],
						useSeriesColors: false
					},
					markers: {
						width: 20,
						height: 4,
		
						strokeColor: '#fff',
		
						radius: 8,
		
					},
					itemMargin: {
						horizontal: 8,
						vertical: 24
					},
		
				},
				tooltip: {
					custom: function ({ seriesIndex, dataPointIndex, w }) {
						const point = w.config.series[seriesIndex].data[dataPointIndex];

						const formatReal2 = (v, style = 'currency') =>
							new Intl.NumberFormat('pt-BR', {
								style: style,
								currency: 'BRL',
							}).format(v)

						const meta_acumulado = formatReal2(point.meta_acumulado)
						const acumulado = formatReal2(point.acumulado)
						
						const dinheiro_novo = formatReal2(point.dinheiro_novo)
						const dinheiro_resgatado = formatReal2(point.dinheiro_resgatado)
						const saldo_liquido = formatReal2(point.saldo_liquido)
						
						return `
							<div class="apexcharts-custom-tooltip">
								<div>
									<span class="dot" style="background: #949799;"></span>
									<strong>Previsto Acumulado: </strong>
									<span> ${meta_acumulado}</span>
								</div>
								<div>
									<span class="dot" style="background: #C11F39;"></span>
									<strong>Real Acumulado: </strong>
									<span> ${acumulado}</span>
								</div>
								<div><strong>Dinheiro Novo: </strong> <span> ${dinheiro_novo}</span></div>
								<div><strong>Resgate: </strong> <span> ${dinheiro_resgatado}</span></div>
								<div><strong>Saldo Liquido: </strong> <span> ${saldo_liquido}</span></div>
							</div>
						`;
					},
				}
			}
		}		
	},
	methods:{
		formatReal2: (v, style = 'currency') =>
			new Intl.NumberFormat('pt-BR', {
				style: style,
				currency: 'BRL',
			}).format(v),

		maiorValorProximoRedondo(numero) {
			// Determine a maior casa decimal (milhões, milhares, etc.)
			const magnitude = ((Math.pow(10, Math.floor(numero).toString().length - 1) / 10));
			// Arredonde para o próximo múltiplo dessa casa decimal
			return Math.ceil(numero / magnitude) * magnitude;
		},

		setSelectEvents() {
			document.querySelector('#semestre_select')?.addEventListener('change', () => {
				const selectSemestres = getChoicesActive('semestre_select', window.listOfChoices)
				console.log(selectSemestres.getValue().value)
				this.filterCaptacao = selectSemestres.getValue().value
				this.setTabelaCaptacaoValues()
			})
			document.querySelector('#usuarios_select')?.addEventListener('change', async () => {
				const selectUsuarios = getChoicesActive('usuarios_select', window.listOfChoices)
				console.log(selectUsuarios.getValue().value)
				this.filterUsuario = selectUsuarios.getValue().value
				await this.iniciarTabelaGastos()
				this.updateTabelaGastosValues()
			})
		},
	
    async iniciarTabelas() {
			await this.iniciarTabelaCaptacao()
			await this.iniciarTabelaGastos()
			this.setTabelasValues()
		},
		setSelectSemestres() {
			const selectSemestres = getChoicesActive('semestre_select', window.listOfChoices)
			if (this.dataCaptacao) {
				const semestresKey = Object.keys(this.dataCaptacao)
				const options = semestresKey.map((f) => {
					return {
						value: f,
						label: f,
					}
				})
				selectSemestres.setChoices([
					...options,
				])
				selectSemestres.setChoiceByValue(semestresKey[0])
				this.filterCaptacao = semestresKey[0]
			} else {
				selectSemestres.setChoices([
					{ value: '', label: 'Não há dados disponíveis' }
				])
				selectSemestres.setChoiceByValue('')
				this.filterCaptacao = ''
			}
		},
		setSelectUsuarios() {
			console.log(this.todosUsuarios)
			if (this.todosUsuarios.length === 0) {
				return
			}
			const selectUsuarios = getChoicesActive('usuarios_select', window.listOfChoices)
			const options = this.todosUsuarios.map(v => {
				return {
					value: v.id,
					label: v.username
				}
			})
			selectUsuarios.setChoices([
				{ value: '', label: 'Selecione um usuário' },
				...options,
			])
			selectUsuarios.setChoiceByValue('')
			this.filterUsuario = ''
		},
		async iniciarTabelaCaptacao() {
			const { data } = await axios.get(`${window.origin}/clientes/captacao/`)
			console.log(data)
			this.dataCaptacao = Object.keys(data).length === 0 ? null : data
			// this.dataCaptacao = null
			this.setSelectSemestres()
		},
		async iniciarTabelaGastos() {
			console.log(this.filterUsuario)
			const filterUser = !this.filterUsuario ? '' : `?user=${this.filterUsuario}`
			const { data } = await axios.get(`${window.origin}/clientes/gastos/${filterUser}`)
			console.log(data)
			this.todosUsuarios = data.todos_users || []
			// this.setSelectUsuarios()
			this.dataGastos.forEach((v, index) => {
				this.dataGastos[index].data = []
			})
			if (data.results.length === 0) return
			const maior_valor = data.results.map(v => v.patrimonio_interno).reduce((max, atual) => Math.max(max, atual), -Infinity)
			console.log('Maior Valor:', maior_valor)
			this.optionsGastos.xaxis.max = this.maiorValorProximoRedondo(maior_valor)
			this.optionsGastos.annotations.yaxis[0].y = data.media_rentabilidade_12_meses
			this.optionsGastos.annotations.yaxis[0].label.text = `Média: ${data.media_rentabilidade_12_meses.toFixed(2).replace('.', ',')}%`
			this.optionsGastos.annotations.xaxis[0].x = data.media_patrimonio_interno
			this.optionsGastos.annotations.xaxis[0].label.text = `Média: ${this.formatReal2(data.media_patrimonio_interno)}`
			data.results.forEach((v, index) => {
				this.dataGastos.filter(w => w.name === politicaOpcoes[v.politica])[0].data.push({
					x: Number(v.patrimonio_interno),
					y: Number(v.rentabilidade_12_meses) || Number(v.rentabilidade_ano),
					label: v.nome,
					color: politicaOpcoesCores[v.politica],
					volatilidade_media_1M: Number(v.medias.volatilidade_media_1M) || 0,
					volatilidade_media_3M: Number(v.medias.volatilidade_media_3M) || 0,
					rentabilidade_bm_12_meses: Number(v.bm_cdi[0].rentabilidade_bm_12_meses),
					rentabilidade_bm_ano: Number(v.bm_cdi[0].rentabilidade_bm_ano)
				})
			})
		},
		setTabelaCaptacaoValues() {
			if (this.chartCaptacao) this.chartCaptacao.destroy();

			const metaAcumulado = !this.dataCaptacao ? [] : this.dataCaptacao[this.filterCaptacao].map((v, index) => {
				return { x: index + 1, y: Number(v.meta_acumulado), label: `Sem ${v.semana}`,
					dinheiro_novo: Number(v.dinheiro_novo),
					dinheiro_resgatado: Number(v.dinheiro_resgatado),
					saldo_liquido: Number(v.saldo_liquido),
					meta_acumulado: Number(v.meta_acumulado),
					acumulado: Number(v.acumulado)
			 	}
			})

			const acumulado = !this.dataCaptacao ? [] : this.dataCaptacao[this.filterCaptacao].map((v, index) => {
				return { x: index + 1, y: Number(v.acumulado), label: `Sem ${v.semana}`,
					dinheiro_novo: Number(v.dinheiro_novo),
					dinheiro_resgatado: Number(v.dinheiro_resgatado),
					saldo_liquido: Number(v.saldo_liquido),
					meta_acumulado: Number(v.meta_acumulado),
					acumulado: Number(v.acumulado)
			 	}
			})

			captacaoXLabel = Object.fromEntries(metaAcumulado.map(point => [point.x, point.label]))

			const seriesSemestre = [
				{
					name: "Previsto Acumulado",
					data: metaAcumulado
				},
				{
					name: "Real Acumulado",
					data: acumulado
				}
			]

			this.optionsCaptacao.series = seriesSemestre
			this.chartCaptacao = new ApexCharts(document.querySelector("#chart_captacao"), this.optionsCaptacao);
			this.chartCaptacao.render();
		},
		setTabelaGastosValues() {
			if (this.chartGastos) this.chartGastos.destroy();
			this.optionsGastos.series = this.dataGastos
			this.chartGastos = new ApexCharts(document.querySelector("#chart_gastos"), this.optionsGastos);
			this.chartGastos.render();
		},
		updateTabelaGastosValues() {
			this.chartGastos.updateOptions(this.optionsGastos);
			this.chartGastos.updateSeries(this.dataGastos);
		},
		setTabelasValues() {
			this.setTabelaCaptacaoValues()
			this.setTabelaGastosValues()
		}
	},
	mounted() {
		// GLOBAL.formatReal2
		setTimeout(async () => {
			await this.iniciarTabelas()
			this.setSelectUsuarios()
			this.setSelectEvents()
		}, 300);
	}


}
