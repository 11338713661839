import ModalResumo from '../components/ModalResumo.js'
import SearchFundacao from '../components/SearchFundacao.js'
import TableAtendimento from '../components/TableAtendimento.js'
import AvatarPersona from '../components/AvatarPersona.js'
import acessos from '../mixins/acessos'
import ConfirmModal from '../components/ConfirmModal.js'
import TaskItem from '../components/TaskItem.js'
import NewTaskItem from '../components/NewTaskItem.js'
import ModalSendEmail from '../components/ModalSendEmail.js'
import sendEmail from '../mixins/sendEmail.js'
import ProcessoItem from '../components/ProcessoItem.js'
import { useCharts } from '../composables/useCharts.js'

const { setColorsInFunctionOfAHexadecimalColor } = useCharts()

const {
	generateRange,
	showToastify,
	api,
	errorDefault,
	destroyApexChart,
	initTipy,
	FormatREALBackEnd,
	getChoicesActive,
	addClassForTime,
	sortedArray,
} = GLOBAL

const selectFundacao = () => document.querySelector('[name=fundacao_lista]')

const selectFundos = () => document.querySelector('#fundos_lista')

const selectEscalas = () => document.querySelector('[name=escala_lista]')

const divFundo = () => document.querySelector('.select-fundo')

const arrPlaceholder = generateRange(8, {
	id: 1,
	politica: 1,
	nome: 'Adelino José Jacques Gambôa Fernandez de Oliveira Netto',
	placeholder: true,
})

let userLogado = document.getElementById('usuario-logado').value

export default {
	mixins: [acessos, sendEmail],

	components: {
		ModalResumo,
		TableAtendimento,
		AvatarPersona,
		ConfirmModal,
		TaskItem,
		NewTaskItem,
		SearchFundacao,
		ModalSendEmail,
		ProcessoItem,
	},

	data() {
		return {
			atendimentos: arrPlaceholder,
			fundacoesSemAtendimentoQuente: [],
			data_inicial: '',
			data_inicial_politica: '',
			estrategias: [],
			fundos: [],
			isFilterActive: false,
			data_final: '',
			data_final_politica: '',
			dataInicioFormatted: '',
			dataFinalFormatted: '',
			prospect: [],
			atendimentosQuentes: [],
			modalConfirm: {},
			haveModalResumo: true,
			chartHomeLoading: true,
			abatask: false,
			abaProcesso: false,
			processos: [],
			semestres: [],
			novaTaskOpen: false,
			tarefas: [],
			totalPage: [],
			semestreFilter: '',
			chartEstrategias: null,
			timingSearch: null,
			statusProcesso: {
				vencido: 0,
				sem_exito: 0,
				em_andamento: 0,
				porc_vencido: 0,
				porc_sem_exito: 0,
				porc_em_andamento: 0,
			},
			chart_estrategias: [],
			isProspectsPaused: false,
			status: {
				atrasado: 0,
				pendente: 0,
				realizado: 0,
			},
			pagination: {
				elementPerPage: 10,
				page: 1,
				totalPage: 1,
				next: '',
				previous: '',
			},
			stateSave: {
				searchs: {
					searchFilterAtendimentos: (arr) => arr,
					searchFilterProspect: (arr) => arr,
				},
			},
		}
	},

	watch: {
		semestres() {
			if (!window.listOfChoices) return

			setTimeout(() => {
				const choicePeriodo = GLOBAL.getChoicesActive(
					'select_periodo',
					listOfChoices
				)
				if (!choicePeriodo) return
				choicePeriodo.clearStore([])

				choicePeriodo.setChoices([
					{ value: '', label: 'Selecione um período' },
					...this.semestres,
				])
				choicePeriodo.setChoiceByValue('')
			}, 200)
		},
		data_inicial() {
			if (this.data_inicial !== 'DD/MM/YYYY') {
				this.formatDate('data_inicial')
			}
		},
		data_final() {
			if (this.data_final !== 'DD/MM/YYYY') {
				this.formatDate('data_final')
			}
		},
		data_inicial_politica() {
			if (this.data_inicial_politica !== 'DD/MM/YYYY') {
				this.formatDate('data_inicial_politica')
			}
		},
		data_final_politica() {
			if (this.data_final_politica !== 'DD/MM/YYYY') {
				this.formatDate('data_final_politica')
			}
		},
	},

	computed: {
		atendimentosView() {
			return this.stateSave.searchs.searchFilterAtendimentos(this.atendimentos)
		},

		prospectsView() {
			return this.stateSave.searchs.searchFilterProspect(this.prospect)
		},

		numeroAtendimentos() {
			return {
				total:
					this.status.pendente + this.status.atrasado + this.status.realizado,
				pendentes: this.status.pendente,
				atrasados: this.status.atrasado,
				realizados: this.status.realizado,
			}
		},

		numeroTarefas() {
			return {
				total: this.isFundacao
					? this.tarefas.filter((task) => task.fundacao)?.length
					: this.tarefas.filter((task) => task.cliente_pf)?.length || 0,

				realizados: this.tarefas.filter((i) => i.status == '2')?.length || 0,
			}
		},
	},

	methods: {
		toggleButtonClasses(eventClick, buttonNoClicked) {
			eventClick.target.classList.remove('quartenary')
			eventClick.target.classList.add('secondary')

			buttonNoClicked.classList.add('quartenary')
			buttonNoClicked.classList.remove('secondary')
		},
		async filtrarPorPeriodo() {
			const choicePeriodo = GLOBAL.getChoicesActive(
				'select_periodo',
				listOfChoices
			)
			if (!choicePeriodo) return
			this.semestreFilter = choicePeriodo.getValue(true)
			await this.getProcessosFundacao()
			this.updateChartEstrategias()
		},

		setStatusProcessos({ count, porcentagem }) {
			this.statusProcesso = {
				vencido: count['Vencidos'],
				sem_exito: count['Sem êxito'],
				em_andamento: count['Em andamento'],

				porc_vencido: porcentagem['Vencidos'],
				porc_sem_exito: porcentagem['Sem êxito'],
				porc_em_andamento: porcentagem['Em andamento'],
			}
		},
		async getSemestres() {
			const url = `/clientes/processos_fundacoes/?semestre=${this.semestreFilter}`
			const { data } = await api.get(url)

			this.semestres = data?.results?.semestres?.map((fundo) => ({
				value: fundo.id,
				label: fundo.semestre,
			}))
		},
		async getInfoEstrategia(idEstrategia) {
			NProgress.start()
			try {
				const { data } = await axios.get(
					`${window.location.origin}/clientes/processos_fundacoes/?&estrategia=${idEstrategia}&semestre=${this.semestreFilter}`
				)

				this.processos = data?.results?.processos
				this.estrategias = data?.results?.estrategias?.map((estrategia) => ({
					value: estrategia.id,
					label: estrategia.nome,
				}))
				this.fundos = data?.results?.fundos?.map((fundo) => ({
					value: fundo.id,
					label: fundo.sigla,
				}))

				this.semestres = data?.results?.semestres?.map((fundo) => ({
					value: fundo.id,
					label: fundo.semestre,
				}))

				const countStatus = data?.results?.count_status
				const porcentagemStatus = data?.results?.porcentagem_status
				this.setStatusProcessos({
					count: countStatus,
					porcentagem: porcentagemStatus,
				})
			} catch (e) {
				errorDefault(e)
			} finally {
				NProgress.done()
			}
		},

		updateChartEstrategias() {
			const series = this.chart_estrategias.map((estrategia) =>
				parseFloat(estrategia.porcentagem)
			)
			console.log({ seriesaq: series })
			const labels = this.chart_estrategias.map((estrategia) => estrategia.nome)
			const ids = this.chart_estrategias.map((estrategia) => estrategia.id)
			const t = this
			const options = {
				series,
				chart: {
					width: 380,

					type: 'pie',
					events: {
						click: function (event, chartContext, config) {
							// debugger

							let posSelected = config.globals?.selectedDataPoints[0]
							const idEstrategiaClicked = ids[posSelected]
							if (!idEstrategiaClicked) return
							t.isFilterActive = true
							console.log({ idEstrategiaClicked })
							t.getInfoEstrategia(idEstrategiaClicked)
						},
					},
				},
				labels,
				dataLabels: {
					show: false,
				},
				colors: GLOBAL.defineColors(labels),
				legend: {
					position: 'bottom',
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 200,
							},
							legend: {
								position: 'bottom',
							},
						},
					},
				],
			}

			this.chartEstrategias?.updateOptions(options)
		},

		initializeChartEstrategiaToggle() {
			const buttonValue = document.querySelector('.chart-valor')
			const buttonPorcentagem = document.querySelector('.chart-porcentagem')
			buttonPorcentagem.addEventListener('click', async (eventClick) => {
				this.createChartEstrategias('porcentagem')
				this.toggleButtonClasses(eventClick, buttonValue)
			})

			buttonValue.addEventListener('click', async (eventClick) => {
				this.createChartEstrategias('valor')
				this.toggleButtonClasses(eventClick, buttonPorcentagem)
			})
		},

		createChartEstrategias(tipo = 'porcentagem') {
			// const atual = tipo === 'porcentagem' ? 'porcentagem' : 'valor'
			destroyApexChart('porcentagem')
			destroyApexChart('valor')

			const series = this.chart_estrategias.map((estrategia) =>
				parseFloat(estrategia[tipo])
			)
			const labels = this.chart_estrategias.map((estrategia) => estrategia.nome)
			const ids = this.chart_estrategias.map((estrategia) => estrategia.id)
			const t = this
			const total = series.reduce((acc, value) => acc + value, 0)

			console.log({ series })
			const options = {
				series,
				chart: {
					width: 380,
					id: tipo,
					type: 'pie',
					events: {
						click: function (event, chartContext, config) {
							let posSelected = config.globals?.selectedDataPoints[0]
							const idEstrategiaClicked = ids[posSelected]
							if (!idEstrategiaClicked) return
							t.isFilterActive = true
							t.getInfoEstrategia(idEstrategiaClicked)
						},
					},
				},
				labels,
				dataLabels: {
					show: false,
				},
				colors: GLOBAL.defineColors(labels),

				legend: {
					position: 'bottom',
				},
				plotOptions: {
					pie: {
						minAngleToShow: 0.01,
					},
				},
				dataLabels: {
					enabled: true,
					formatter: function (val, { seriesIndex }) {
						const percentage = ((series[seriesIndex] / total) * 100).toFixed(2)
						return `${percentage}%`
					},
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 200,
							},
						},
					},
				],
			}

			this.chartEstrategias = new ApexCharts(
				document.querySelector('#chart-estrategias'),
				options
			)
			this.chartEstrategias.render()
		},
		async getProcessosFundacao() {
			console.log('chamou essa aqq')
			try {
				const url = `/clientes/processos_fundacoes/?semestre=${this.semestreFilter}`
				const { data } = await api.get(url)
				this.processos = data?.results?.processos

				this.estrategias = data?.results?.estrategias?.map((estrategia) => ({
					value: estrategia.id,
					label: estrategia.nome,
				}))

				this.fundos = data?.results?.fundos?.map((fundo) => ({
					value: fundo.id,
					label: fundo.sigla,
				}))
				this.semestres = data?.results?.semestres?.map((fundo) => ({
					value: fundo.id,
					label: fundo.semestre,
				}))

				const countStatus = data?.results?.count_status
				const porcentagemStatus = data?.results?.porcentagem_status
				this.setStatusProcessos({
					count: countStatus,
					porcentagem: porcentagemStatus,
				})

				this.chart_estrategias = data?.results.porcentagem_estrategias
			} catch (error) {
				console.log(error)
			}
		},
		async getFundacoesSemAtendimentoQuente() {
			try {
				const { data } = await api.get(
					'/clientes/ativa_atendimento_quente_fundacao/'
				)

				return data || null
			} catch (error) {
				console.log(error)
			}
		},

		async sendProcessoEditadoSucess() {
			// showToastify(text)
			await this.getProcessosFundacao()
			initTipy()
		},

		async limparFiltroEstrategia() {
			// debugger
			this.isFilterActive = false
			await this.getProcessosFundacao()
			this.updateChartEstrategias()
			initTipy()
		},

		async ativaAtendimentoQuente(payload) {
			try {
				const fundosParam =
					!payload.idFundos || payload.idFundos === 'no-founds'
						? ''
						: payload.idFundos
				const { data } = await api.get(
					`/clientes/ativa_atendimento_quente_fundacao/?id=${payload.idFundacao}&sigla=${fundosParam}&escala=${payload.idEscala}`
				)
				this.clearModalAtivacaoValues()
				showToastify('✅ Atendimento quente adicionado')

				return data?.lista_fundacoes || []
			} catch (error) {
				console.log(error)
			}
		},

		async addAtendimentoQuente() {
			const choiceFundacao = GLOBAL.getChoicesActive(
				selectFundacao()?.id,
				listOfChoices
			)
			const choiceEscala = GLOBAL.getChoicesActive(
				selectEscalas()?.id,
				listOfChoices
			)
			if (!choiceFundacao) return
			const idFundacao = choiceFundacao.getValue(true)
			const idFundos = Array.from(selectFundos().options)
				.map((option) => option.value)
				.join(',')
			const idEscala = choiceEscala.getValue(true)
			const payload = {
				idFundacao,
				idFundos,
				idEscala,
			}
			this.fundacoesSemAtendimentoQuente = await this.ativaAtendimentoQuente(
				payload
			)

			choiceFundacao.removeActiveItems()
			choiceEscala.removeActiveItems()

			await this.getAtendimentosQuentes()
			this.setChoicesFundacaoSemAtendimentoQuente()
		},

		addEventButtons() {
			const btnAtiva = document.querySelector('.ativa-atendimento')
			const btnCancelar = document.querySelector('.cancel-ativacao')
			const btnFechar = document.querySelector('.close-btn-ativacao')
			if (!btnAtiva || !btnCancelar || !btnFechar) return
			btnAtiva.addEventListener('click', () => {
				this.addAtendimentoQuente()
			})
			btnCancelar.addEventListener('click', () => {
				this.clearModalAtivacaoValues()
			})
			btnFechar.addEventListener('click', () => {
				this.clearModalAtivacaoValues()
			})
		},

		clearModalAtivacaoValues() {
			const choiceFundacao = GLOBAL.getChoicesActive(
				selectFundacao().id,
				listOfChoices
			)
			const choiceEscala = GLOBAL.getChoicesActive(
				selectEscalas().id,
				listOfChoices
			)
			const choiceFundos = GLOBAL.getChoicesActive(
				selectFundos().id,
				listOfChoices
			)

			choiceFundacao.setChoiceByValue('')
			choiceEscala.setChoiceByValue('')

			// choiceFundacao.removeActiveItems()
			// choiceEscala.removeActiveItems()

			choiceFundos.removeActiveItems()
			choiceFundos.setChoices([])

			selectFundacao().value = ''
			selectEscalas().value = ''
			// selectFundos().value = ''
		},

		addEventSelectFundacao() {
			const btnAtiva = document.querySelector('.ativa-atendimento')

			if (!selectFundacao()) return
			selectFundacao().addEventListener('change', () => {
				if (selectFundacao().value && selectEscalas().value) {
					btnAtiva.classList.remove('disabled')
				} else {
					btnAtiva.classList.add('disabled')
				}
			})
			selectEscalas().addEventListener('change', () => {
				if (selectFundacao().value && selectEscalas().value) {
					btnAtiva.classList.remove('disabled')
				} else {
					btnAtiva.classList.add('disabled')
				}
			})
		},

		setChoicesFundacaoSemAtendimentoQuente() {
			const choiceFundacao = GLOBAL.getChoicesActive(
				selectFundacao().id,
				listOfChoices
			)
			choiceFundacao.clearStore([])
			const options = this.fundacoesSemAtendimentoQuente?.map((fundacao) => {
				return {
					value: fundacao.id,
					label: fundacao.sigla,
				}
			})
			choiceFundacao.setChoices([
				{ value: '', label: 'Selecione uma fundação' },
				...options,
			])
			choiceFundacao.setChoiceByValue('')
		},

		setChoicesFundosByFundacao(fundos) {
			const choiceFundo = GLOBAL.getChoicesActive(
				selectFundos().id,
				listOfChoices
			)
			choiceFundo.clearStore([])
			if (fundos?.length) {
				const options = fundos.map((f) => {
					return {
						value: f.id,
						label: f.sigla,
					}
				})
				choiceFundo.setChoices([...options])
			} else {
				choiceFundo.setChoices([{ value: 'no-founds', label: 'Sem fundos' }])
				choiceFundo.setChoiceByValue('no-founds')
			}
		},

		setChoicesEscalasByFundacao(escalas) {
			const choiceEscala = GLOBAL.getChoicesActive(
				selectEscalas().id,
				listOfChoices
			)
			choiceEscala.clearStore([])
			const options = escalas.map((f) => {
				return {
					value: f.id,
					label: f.nome,
				}
			})
			choiceEscala.setChoices([
				{ value: '', label: 'Selecione uma escala' },
				...options,
			])
			choiceEscala.setChoiceByValue('')
		},

		formatDate(date) {
			if (this[date]) {
				const [year, month, day] = this[date].split('/')
				this.dataInicioFormatted = `${day}/${month}/${year}`
			} else {
				this.dataInicioFormatted = ''
			}
		},
		formatDateFinal() {
			if (this.data_final) {
				const [year, month, day] = this.data_final.split('/')
				this.dataFinalFormatted = `${day}/${month}/${year}`
			} else {
				this.dataFinalFormatted = ''
			}
		},
		//!!! Modal methods Home
		modalOpen(item) {
			this.$refs.modalResumo.OpenModalResumo(item)
		},

		modalOpenWithAtendimento(item) {
			this.$refs.modalResumo.modalOpenAtendimento(item)
		},

		handleOpenModalConfirmHome(data) {
			this.$refs.modalResumo.handleOpenModalConfirm(item)
		},
		totalPageCalcula() {
			let array = Array.from(Array(this.pagination.totalPage), (e, i) => i + 1)

			this.totalPage = array
		},

		openModalProspectHome(item) {
			this.$refs.modalResumo.openModalProspect(item)
		},

		openModalCancelAtendimentoQuenteHome(item) {
			this.$refs.modalResumo.openModalCancelAtendimentoQuente(item)
		},
		//!!! modal Confirm Home
		openModalProspect(prospect) {
			const nameCurto = prospect.nome.split(' ')[0]

			this.modalConfirm = {
				open: true,
				text: `Deseja converter <b class="text-primary-pure">${nameCurto}</b> em um cliente `,
				data: prospect,
				id: 'prospect',
			}
		},
		selectRentabilidade(type, client) {
			this.$refs.modalResumo.selectRentabilidade(type, client)
		},
		//-------------------------------------------

		//!!! GET  🍃 Home
		async getAtendimentos() {
			try {
				const dataInicialPoliticaFormated = this.data_inicial_politica
					.split('/')
					.reverse()
					.join('-')
				const dataFinalPoliticaFormated = this.data_final_politica
					.split('/')
					.reverse()
					.join('-')
				const end = this.isFundacao
					? '?exibicao=fundacao'
					: '?exibicao=cliente_pf'
				const request = await api.get(
					`/api/atendimentos/${end}&data_inicial=${dataInicialPoliticaFormated}&data_final=${dataFinalPoliticaFormated}`
				)
				const data = await request.data.results

				this.atendimentos = data?.atendimentos
				this.status = data?.status

				this.isFundacao && this.initChartRealizados()
				setTimeout(() => {
					initTipy()
				}, 150)
			} catch (e) {
				errorDefault(e)
			}
		},

		async getProspect(paramUrl) {
			try {
				const url = paramUrl ?? `/api/clientepf/?prospect=true`

				const request = await api.get(url)

				this.paginationChange(request.data)
				const data = await request.data.results
				this.prospect = data

				setTimeout(() => initTipy(), 150)
			} catch (e) {
				errorDefault(e)
			}
		},

		paginationChange(data) {
			this.pagination = {
				...this.pagination,
				elementPerPage: 10,
				totalPage: Math.ceil(data.count / this.pagination.elementPerPage),
				next: data.next,
				previous: data.previous,
			}

			this.totalPageCalcula()
		},

		async next() {
			await this.getProspect(this.pagination.next)
			this.pagination.page = this.pagination.page + 1
		},

		async previous() {
			await this.getProspect(this.pagination.previous)
			this.pagination.page = this.pagination.page - 1
		},

		async clickPage(page) {
			await this.getProspect(`/api/clientepf/?prospect=true&page=${page}`)
			this.pagination.page = page
		},

		async getAtendimentosQuentes() {
			try {
				const url = this.isFundacao
					? `/api/fundacao/?atendimento_quente=true`
					: `/api/clientepf/?atendimento_quente=true`

				const request = await api.get(url)
				const data = await request.data.results
				this.atendimentosQuentes = data

				setTimeout(() => initTipy(), 150)
			} catch (e) {
				errorDefault(e)
			}
		},
		//--------------------------------------------
		//!!! SENDS HOME

		async sendProspectFalse(prospect) {
			const obj = {
				prospect: false,
			}

			try {
				const request2 = await axios.patch(`/api/clientepf/${prospect.id}/`, {
					...obj,
				})
				await this.getProspect()

				const nameCurto = this.isFundacao
					? request2.data.sigla
					: request2.data.nome.split(' ')[0]

				showToastify(`✅ ${nameCurto} foi convertido(a) em cliente.`)
				this.modalConfirm = {}
			} catch (e) {
				errorDefault(e)
			}
		},

		async sendNewProspect(e) {
			e.preventDefault()

			const form = document.querySelector('#form_novo_prospect')

			if (!form.checkValidity()) {
				form
					.querySelectorAll('.field')
					.forEach((i) => addClassForTime(i, 'required-field', 3000))

				showToastify('⚠️ Preencha todos os campos obrigatórios para enviar')

				return
			} else {
				const fields = form.querySelectorAll('[name]')

				const obj = Array.from(fields).reduce((acc, { name, value }) => {
					const valueLimpo =
						name == 'patrimonio_externo'
							? value
								? FormatREALBackEnd(value)
								: 0
							: value
					acc[name] = valueLimpo
					return acc
				}, {})

				obj.prospect = true
				obj.usuario_responsavel = [userLogado] //necessario enviar um array

				try {
					NProgress.start()
					const request = await axios.post(`/api/clientepf/`, obj)

					await this.getProspect()
					showToastify('✅ Prospect criado com sucesso')
					modal_padrao.closeModal()

					// if (this.clienteAtivo.id) {
					// 	await this.getDadosModal(this.clienteAtivo.id, true);

					// }
				} catch (e) {
					errorDefault(e)
				} finally {
					NProgress.done()
				}
			}
		},

		async sendNewTask(obj) {
			const { data } = await this.$refs.modalResumo.sendNewTask(obj)
			this.activeAbaTask(false)
			this.novaTaskOpen = false
			setTimeout(() => {
				const el = document.querySelector(`.item-task[data-id='${data.id}']`)
				el && addClassForTime(el, '!border-primary-pure', 3000)
				el && el.scrollIntoView({ behavior: 'smooth', block: 'start' })
			}, 300)
		},

		async taskEnviadaSucess(text) {
			await this.activeAbaTask(false)
			showToastify(text)
		},

		//-----------------------------------------------
		searchInHome(e) {
			if (!this.stateSave.inputActive) {
				this.stateSave.atendimentosHome = this.atendimentos
				this.stateSave.inputActive = true
			}
			const target = e.target
			const value = target.value

			if (value != '') {
				this.atendimentosView =
					this.stateSave.searchs.searchFilterAtendimentos = (arr) =>
						arr?.filter((i) =>
							(this.isFundacao ? i.nome_fundacao : i.nome_cliente_pf)
								.toLowerCase()
								.includes(value.toLowerCase())
						)
			} else {
				this.atendimentos = this.stateSave.atendimentosHome
				this.stateSave = {
					inputActive: false,
					searchs: {
						searchFilterAtendimentos: (arr) => arr,
						searchFilterProspect: (arr) => arr,
					},
				}
			}
		},

		debounceFunction: (fn, timing, wait = 200) => {
			return (...args) => {
				clearTimeout(timing)
				timing = setTimeout(() => fn(...args), wait)
			}
		},

		async initChartRealizados() {
			const request = await api.get(
				`${window.location.origin}/atendimento/grafico_realizados_semana/`
			)

			const data = await request.data

			const dataChart = [
				{
					name: 'Realizado',
					type: 'column',
					data: Object.values(data.grafico_realizado),
				},
				{
					name: 'Previsto',
					type: 'line',
					data: Object.values(data.grafico_previsto),
				},
			]

			window.chartRealizados?.updateOptions({
				series: dataChart,
				labels: Object.keys(data.grafico_previsto),
			})

			this.chartHomeLoading = false
		},

		async getTarefas() {
			try {
				const dataInicialFormated = this.data_inicial
					.split('/')
					.reverse()
					.join('-')
				const dataFinalFormated = this.data_final.split('/').reverse().join('-')

				const { data } = await api.get(
					`/api/tarefas/?data_inicial=${dataInicialFormated}&data_final=${dataFinalFormated}`
				)

				this.tarefas = data.results?.map((i) => ({
					...i,
					nome: this.isFundacao ? i.nome_fundacao : i.nome_cliente_pf,
					politica: this.isFundacao ? i.politica_fundacao : i.politica_cliente,
				}))
				this.tarefas = this.ordenateTarefas()
			} catch (e) {
				errorDefault(e)
			}
		},

		async handlePeriodoTasks() {
			await this.getTarefas()
		},
		async handlePeriodoPolitica() {
			await this.getAtendimentos()
			this.$refs.tableAtendimento.atualizarAgendamentoDetalhes()
		},
		setDates() {
			let hoje = new Date()
			// let dataInicial = Quasar.date.subtractFromDate(hoje, { months: 2 })
			let dataInicial = '01/01/2025'
			let dataFinal = Quasar.date.addToDate(hoje, { days: 30 })

			this.data_inicial = Quasar.date.formatDate(dataInicial, 'DD/MM/YYYY')
			this.data_final = Quasar.date.formatDate(dataFinal, 'DD/MM/YYYY')

			this.data_inicial_politica = Quasar.date.formatDate(
				dataInicial,
				'DD/MM/YYYY'
			)
			this.data_final_politica = Quasar.date.formatDate(dataFinal, 'DD/MM/YYYY')
		},

		ordenateTarefas() {
			return this.tarefas
				.map((i) => {
					if (i.get_status == '3') i.ordem = 1
					if (i.get_status == '1') i.ordem = 2
					if (i.get_status == '2') i.ordem = 3
					if (i.get_status == '0') i.ordem = 4
					return i
				})
				.sort((a, b) => a.ordem - b.ordem)
		},

		async activeAbaTask(progress = true) {
			progress && NProgress.start()

			await this.getTarefas()

			// this.ordenateTarefas()

			if (progress) {
				this.abatask = true
			}
			progress && NProgress.done()
		},

		async activeAbaProcesso(progress = true) {
			this.processos = []
			progress && NProgress.start()

			await this.getProcessosFundacao()

			// this.ordenateTarefas()
			initTipy()

			if (progress) {
				this.abaProcesso = true
				this.createChartEstrategias()
			}
			progress && NProgress.done()
		},

		deactivateAbaTask() {
			this.abatask = false
		},

		getPercent(real, total) {
			const calc = (real / total) * 100
			return `${isNaN(calc.toFixed()) ? 0 : calc.toFixed()}%`
		},
		sortedArray({ event, key, dataKey, number }) {
			this.stateSave.searchs.searchFilterAtendimentos = (a) => a
			const target = event.currentTarget
			const att = target.getAttribute('order')

			target
				.closest('thead')
				.querySelectorAll('th')
				.forEach((el) => el !== target && el.removeAttribute('order'))

			if (att == 'up') target.setAttribute('order', 'down')
			if (att == 'down') target.setAttribute('order', 'up')
			if (!att) target.setAttribute('order', 'up')

			const clone = this[dataKey]

			const result = clone.sort((a, b) => {
				const A = number ? a[key] : a[key].toLowerCase()
				const B = number ? b[key] : b[key].toLowerCase()

				if (A < B) return -1
				if (B > A) return 1

				return 0
			})

			this[dataKey] = att == 'up' ? result : result.reverse()
		},

		handleClickSearch(id) {
			this.modalOpen({ id })
		},

		changeScrollState() {
			this.isProspectsPaused = !this.isProspectsPaused
		},

		async getValuesSemAtendimentoQuente() {
			const prospect_quente_values =
				this.isFundacao && (await this.getFundacoesSemAtendimentoQuente())
			this.fundacoesSemAtendimentoQuente =
				prospect_quente_values.lista_fundacoes
			this.isFundacao && this.setChoicesFundacaoSemAtendimentoQuente()
			this.isFundacao &&
				this.setChoicesFundosByFundacao(prospect_quente_values.todos_os_fundos)
			this.isFundacao &&
				this.setChoicesEscalasByFundacao(
					prospect_quente_values.todas_as_escalas
				)
		},
	},

	async created() {
		this.activeAbaProcesso(false)
		this.setDates()
		!this.isFundacao && this.getProspect()
		this.getAtendimentosQuentes()
		await this.getAtendimentos()
		this.haveProspect = true
		await this.getValuesSemAtendimentoQuente()
		this.activeAbaTask(false)
	},

	async mounted() {
		await this.$refs.modalResumo.getTags()
		this.isFundacao && this.addEventButtons()
		this.isFundacao && this.addEventSelectFundacao()
		this.initializeChartEstrategiaToggle()
		this.isFundacao && (await this.getSemestres())
		initTipy()

		this.stateSave.searchs.searchFilterAtendimentos = (newValue, oldValue) => {
			const maped = newValue
				.map((i) => {
					if (i.status == 'Pendente') i.ordem = 1
					if (i.status == 'Atrasado') i.ordem = 2
					if (i.status == 'Realizado') i.ordem = 3
					return i
				})
				.sort((a, b) => a.ordem - b.ordem)

			return maped
		}
	},

	delimiters: ['{*', '*}'], //delimitadores pra n conflitar com o django
}
