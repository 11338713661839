import { useApi } from '../composables/useApi'

export function emailFundacaoService() {
	const api = useApi()

	async function getTemplatesEmail(query = '?=') {
		return api.query(`${window.origin}/clientes/email_templates/${query}`)
	}

	async function getFundacoesContatos(query = '?=') {
		return api.query(`${window.origin}/clientes/fundacao_contatos/${query}`)
	}
	return {
		getTemplatesEmail,
		getFundacoesContatos,
		...api,
	}
}
