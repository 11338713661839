const { ref } = Vue
import { className, Grid, h, html as htmlGrid } from 'gridjs'
import ConfirmModal from '../components/ConfirmModal.js'
import ModalAprovacao from '../components/ModalAprovacao.js'
import { emailFundacaoService } from '../services/email-fundacao.service.js'
import Quill from 'quill'

const { getChoicesActive } = GLOBAL

const { getTemplatesEmail, getFundacoesContatos, isLoading } =
	emailFundacaoService()
export default {
	emits: [],

	props: {},
	components: {
		ModalAprovacao,
		ConfirmModal,
	},

	data() {
		return {
			tableEmails: [],
			listaEmail: true,
			isCreateEmail: true,
			templates: {
				results: [],
			},
			fundacoesList: {
				results: [],
			},
			isLoading: isLoading,
			template: '',
			corpo_email: '',
			assunto: '',
			quill: null,
		}
	},
	watch: {
		template() {
			console.log(this.template)
			const templateSelecionado = this.templates?.results?.find(
				(template) => template.id_template == this.template
			)
			if (!templateSelecionado) return
			this.assunto = templateSelecionado.assunto
			this.quill.root.innerHTML = templateSelecionado.corpo
		},

		fundacoesList() {
			const opcoes = this.fundacoesList?.results?.map((fundacao) => ({
				value: fundacao.email_principal,
				label: fundacao.sigla_fundacao,
			}))

			const selectFundacoes = getChoicesActive(
				'email_fundacao',
				window.listOfChoices
			)

			selectFundacoes.setChoices([{ value: '', label: 'Selecione' }, ...opcoes])
			selectFundacoes.setChoiceByValue('')
		},

		templates() {
			const opcoes = this.templates?.results?.map((fundacao) => ({
				value: fundacao.id_template,
				label: fundacao.assunto,
			}))

			const selectTemplates = getChoicesActive(
				'email_modelo',
				window.listOfChoices
			)

			selectTemplates.setChoices([{ value: '', label: 'Selecione' }, ...opcoes])
			selectTemplates.setChoiceByValue('')
		},
	},
	methods: {
		openCreateEmail() {
			this.listaEmail = false
			this.isCreateEmail = true
		},

		openVisualizaEmail() {
			this.listaEmail = false
			this.isCreateEmail = false
		},

		closeCreateEmail() {
			this.listaEmail = true
			this.isCreateEmail = true
		},

		updateCorpoEmail() {
			if (!this.quill) {
				return
			}
			this.quill.on('text-change', () => {
				const html = this.quill.root.innerHTML
				this.corpo_email = html
			})
		},

		iniciarTabelaGenerica(
			tableSelector,
			columns,
			url,
			customThenCallback,
			customTotalCallback
		) {
			const table = document.querySelector(tableSelector)

			const gridInstance = new Grid({
				search: true,
				resizable: true,
				columns: columns,
				data: [],
				autoWidth: true,
				width: '100%',
				sort: false,
				server: {
					url: url,
					then: (data) => customThenCallback(data),
					total: (data) => customTotalCallback(data).length,
				},
				pagination: {
					enabled: false,
					limit: 10,
					summary: false,
					server: {
						url: (urlApi, page, limit) =>
							`${urlApi}&page=${++page}&paginate_by=${limit}`,
					},
				},
				style: {
					container: { border: 'none' },
					table: { border: 'none' },
					tr: { border: 'none' },
					th: { 'border-bottom': '1px solid #e9ecef' },
					td: {
						'border-bottom': '1px solid #e9ecef',
						cursor: 'pointer',
						color: '#495057',
						'font-family': 'Roboto Flex',
						'font-size': '14px',
						'font-weight': 500,
						'line-height': '150%',
						'letter-spacing': '-0.28px',
					},
					footer: { border: 'none' },
				},
				language: {
					search: { placeholder: 'Busque aqui...' },
					pagination: { previous: ' Anterior', next: 'Próximo ' },
				},
			})

			gridInstance.render(table)
			return gridInstance
		},

		iniciartableEmails() {
			const columns = [
				{ id: 'ID', name: 'ID', hidden: true, width: '100px' },

				{ id: 'Para', name: 'Para' },

				{ id: 'Assunto', name: 'Assunto', hidden: false },
				{
					id: 'Enviados',
					name: 'Enviados',
					formatter: (cell) => this.formatOfficersNames([{ nome: cell }]),
				},

				{
					id: 'acao',
					name: '',
					width: '80px',
					formatter: (cell, row) => this.renderHistoricoAcoes(row),
				},
			]

			this.tableEmails = this.iniciarTabelaGenerica(
				'#table_emails',
				columns,
				`${window.origin}/atendimento/solicitacoes/?`,
				(data) => this.mapAndFilterSolicitations(data),
				(data) => this.mapAndFilterSolicitations(data)
			)
		},

		renderAcoes(row) {
			return h(
				'div',
				{
					className: 'flex gap-2 justify-between',
				},
				[
					h('ion-icon', {
						name: 'checkmark-outline',
						className: 'success',
						onClick: () => this.openModalSolicitacao(row, 'aprovar'),
					}),
					h('ion-icon', {
						name: 'close-outline',
						className: 'error',
						onClick: () => this.openModalSolicitacao(row, 'recusar'),
					}),
				]
			)
		},

		renderHistoricoAcoes(row) {
			return h('ion-icon', {
				name: 'search-outline',
				onClick: () => this.openModalHistorico(row),
			})
		},
	},

	async mounted() {
		this.quill = new Quill(this.$refs.editor, {
			theme: 'snow',
			modules: {
				toolbar: [
					[{ header: [1, 2, false] }],
					['bold', 'italic', 'underline'],
					[{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
					[{ color: [] }, { background: [] }],
					['clean'],
				],
			},
		})
		this.updateCorpoEmail()

		this.fundacoesList = await getFundacoesContatos()
		this.templates = await getTemplatesEmail()

		this.iniciartableEmails()
	},
	delimiters: ['{*', '*}'],
}
