export default {
	props: ['editando', 'estrategias', 'fundos', 'semestres'],
	emits: ['enviar', 'cancelar'],

	data() {
		return {
			data: {
				fundacao: '',
				estrategia: '',
				fundo: '',
				valor: '',
				comentario: '',
				semestre: '',
			},
		}
	},

	watch: {
		editando() {},
		estrategias() {
			const estrategiasChoices = GLOBAL.getChoicesActive(
				'estrategia',
				listOfChoices
			)
			estrategiasChoices.setChoices(
				[...this.estrategias],
				'value',
				'label',
				true
			)
		},
		fundos() {
			const fundosChoices = GLOBAL.getChoicesActive('fundo', listOfChoices)
			fundosChoices.setChoices([...this.fundos], 'value', 'label', true)
		},

		semestres() {
			const semestresChoices = GLOBAL.getChoicesActive(
				'semestre',
				listOfChoices
			)
			semestresChoices.setChoices([...this.semestres], 'value', 'label', true)
		},
	},

	mounted() {
		this.$nextTick(() => {
			const select = document.querySelector('[js-choices]')
			if (select) {
				new Choices(select) // Inicializa Choices.js
			}
		})
	},

	methods: {
		handleChange({ target }) {
			const value = target.value
			this.data[target.getAttribute('name')] = value.trim()
		},
		prevent(e) {
			e.preventDefault()
		},

		enviar() {
			this.$emit('enviar', this.data)
			GLOBAL.initTipy()
			this.data = {
				fundacao: '',
				estrategia: '',
				fundo: '',
				valor: '',
				comentario: '',
				semestre: '',
			}
		},

		cancelar() {
			this.$emit('cancelar')
			this.data = {
				fundacao: '',
				estrategia: '',
				fundo: '',
				valor: '',
				comentario: '',
			}
		},

		onBeforeEnter(el) {
			gsap.set(el, {
				height: 0,
			})
		},

		onEnter(el, done) {
			gsap.timeline({ ease: 'power1', delay: 0 }).to(el, {
				height: 'auto',
				duration: 0.2,
			})
			this.$refs.new_processo.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			})

			done()
		},
	},

	template: html`
		<Transition @before-enter="onBeforeEnter" @enter="onEnter">
			<div
				class="p-16 border !h-[33rem] border-primary-pure rounded-md new-task-el overflow-hidden "
				ref="new_processo">
				<p class="text-primary-pure  font-medium mb-8">Novo Processo</p>
				<form class="flex flex-col items-center gap-8 " @submit="prevent">
					<div class="flex-1 flex flex-col  w-full">
						<label class="label">Estratégia</label>
						<div class="select-container h-40">
							<select
								v-model="data.estrategia"
								name="estrategia"
								id="estrategia"
								js-choices>
								<option value="" selected>Escolha uma estratégia</option>
							</select>
						</div>
					</div>
					<div class="flex-1 flex flex-col  w-full">
						<label class="label">Semestre</label>
						<div class="select-container h-40">
							<select
								v-model="data.semestre"
								name="semestre"
								id="semestre"
								js-choices>
								<option value="" selected>Escolha um semestre</option>
							</select>
						</div>
					</div>
					<div class="flex-1 flex flex-col  w-full">
						<label class="label">Fundo</label>
						<div class="select-container h-40">
							<select v-model="data.fundo" name="fundo" id="fundo" js-choices>
								<option value="" selected>Escolha um fundo</option>
							</select>
						</div>
					</div>
					<div class="flex-1 flex flex-col  w-full">
						<label class="label">Valor</label>
						<input
							type="text"
							name="valor"
							id="valor_processo"
							data-mask="000.000.000.000.000,00"
							data-mask-reverse="true"
							placeholder="R$"
							class="w-full p-10 input-text" />
					</div>
					<div class="flex-1 flex flex-col gap-4 w-full">
						<label class="label">Comentário</label>
						<textarea
							v-model="data.comentario"
							name="comentario"
							class="w-full p-10 input-text h-[5rem]"></textarea>
					</div>

					<div class=" flex justify-between mt-8 w-full">
						<button type="button" class="btn secondary" @click="cancelar">
							Cancelar
						</button>
						<button type="button" class="btn" @click="enviar">Enviar</button>
					</div>
				</form>
			</div>
		</Transition>
	`,
}
