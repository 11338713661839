import { Grid, h, html as htmlGrid } from 'gridjs'
import SelectMultiple from '../components/SelectMultiple'
import ModalResumo from '../components/ModalResumo.js'
import { investimentosService } from '../services/investimentos.service.js'
import { capitalize } from 'lodash'

const { getInvestimentosPf, getMetaDadosInvestimentos } = investimentosService()

const URLS = {
	investimentos_clientes_pf_api: '/clientes/investimentos/clientes_pf_api/',
}
export default {
	emits: [],

	props: {},

	data() {
		return {
			tableInvestimentos: null,
			investimentosUsuario: [],
			haveModalResumo: true,
			query: '?',
			model: {
				filtroInvestimentos: [],
				paginationLimitInvestimentos: 10,
				currentPage: 1,
			},
			totalRegistros: 0,
			optionsOfficers: [],
			sort: true,
			usuarios: [],
			columns: [
				{ id: 'id', name: 'ID', hidden: true },
				{ id: 'politica', name: 'Politica', hidden: true },
				{
					id: 'nome',
					name: 'Cliente PF',
					attributes: {
						'sticky-column-1': true,
						'sticky-header': true,
					},
					hidden: false,
					width: '180px',
					formatter: (cell, row) => {
						const politica = row?._cells[1]?.data

						if (cell === 'Media')
							return htmlGrid(html`<div
								class="flex items-center"
								data-totalizador>
								Média por investimento
							</div>`)
						else {
							return htmlGrid(`<div class="inline-flex items-center max-w-[180px] "  >
							<svg class="h-40 w-40 shrink-0" style="${GLOBAL.generateAvatarColor(politica)}">
									<use xlink:href="#icon_person"></use>
								</svg>
							<span class="one-line max-w-[180px]" title="${cell}">${cell || ''}</span>
						</div>`)
						}
					},
					sort: {
						compare: (a, b) => {
							if (a === 'Media' || b === 'Media') return 0
							if (a > b) {
								return 1
							} else if (a < b) {
								return -1
							} else {
								return 0
							}
						},
					},
				},
				{
					id: 'usuario_responsavel',
					name: 'Officer',
					hidden: false,
					width: '100px',
					attributes: {
						'sticky-column-2': true,
						'sticky-header': true,
						center: true,
					},
					formatter: (cell, row) => {
						if (cell) {
							return htmlGrid(`<div style="display:flex; flex-direction:column; gap:2px; max-width:100px; overflow: hidden; text-overflow: ellipsis; truncate" >
							${cell
								?.map((item) => {
									const nameFormated = item
										.split('.')
										.map((word) => capitalize(word))
										.join(' ')
									return `<span>${nameFormated || ''}</span>`
								})
								.join(' ')}
						</div>`)
						} else {
							return htmlGrid(`<span></span>`)
						}
					},
				},
				{
					id: 'dados_investimento_renda_fixa',
					name: 'Renda fixa',
					width: '230px',
					attributes: {
						center: true,
					},
					formatter: (cell, row) => {
						if (cell.media) {
							return htmlGrid(`
								<div style='width: 100%;'> 
									<span style='color: black; font-weight: bold;'>${cell.media.toPrecision(
										3
									)}%</span>
								</div>	
							`)
						} else if (cell) {
							// console.log(cell)
							const { data_ipca, data_pre, data_pos, totalGlobalPorcentagem } =
								cell

							const ipcaPorcentagem = data_ipca.porcentagem_global
							const prePorcentagem = data_pre.porcentagem_global
							const posPorcentagem = data_pos.porcentagem_global

							return htmlGrid(`
							<div>
								<ul class='flex flex-col items-center font-bold text-black'>
									<li>Ipca: ${ipcaPorcentagem}</li>
									<li>Pre: ${prePorcentagem}</li>
									<li>Pos: ${posPorcentagem}</li>
								</ul>
								<span class='font-bold text-primary-pure'>Total: ${totalGlobalPorcentagem.valor.toPrecision(
									3
								)} </span>
							</div>
						`)
						}
					},
					sort: false,
				},
			],
		}
	},
	components: {
		SelectMultiple,
		ModalResumo,
	},

	methods: {
		openModal(id, politica) {
			this.$refs.modalResumo.OpenModalResumo({
				id,
				politica,
				atendimentoID: null,
			})
		},

		openFiltros() {
			modal_padrao_vue.openModal('investimentos_filtros')
		},

		iniciarTabelaGenerica(
			tableSelector,
			columns,
			url,
			customThenCallback,
			customTotalCallback
		) {
			const table = document.querySelector(tableSelector)

			const gridInstance = new Grid({
				search: false,
				resizable: true,
				columns,
				data: [],
				autoWidth: true,
				sort: false,
				server: {
					url: url,
					then: (data) => customThenCallback(data),
					total: (data) => customTotalCallback(data),
				},
				pagination: {
					enabled: true,
					limit: this.model.paginationLimitInvestimentos,
					summary: false,
					server: {
						url: (urlApi, page, limit) => {
							this.model.currentPage = ++page
							this.setQueryUrl()
							return `${window.location.origin}${URLS.investimentos_clientes_pf_api}${this.query}`
						},
					},
				},

				style: {
					container: {
						border: 'none',
					},
					table: {
						border: 'none',
						'white-space': 'nowrap',
					},
					tr: { border: 'none' },
					th: {
						'border-bottom': '1px solid #e9ecef',
						padding: '8px 8px',
					},
					td: {
						'border-bottom': '1px solid #e9ecef',
						color: '#495057',
						'font-family': 'Roboto Flex',
						'font-size': '13px',
						'font-weight': 500,
						'line-height': '150%',
						'letter-spacing': '-0.28px',
						padding: '4px',
						'text align': 'center',
					},
					footer: { border: '' },
				},

				language: {
					search: { placeholder: 'Busque aqui...' },
					pagination: { previous: ' Anterior', next: 'Próximo ' },
				},
			})
			gridInstance.on('rowClick', (...args) => {
				// this.openModal(id, politica)
			})

			gridInstance?.render(table)
			return gridInstance
		},

		formatDataInvestimentosTable(data) {
			// console.log(data)

			// func para achatar o array tipos de investimentos para um objeto com campos para cada item do array
			const dataTransformado = data.results.map((dataItem) => {
				const tiposInvestimentos = dataItem.tipos_investimento.reduce(
					(tiposInvestimentosFields, tipoInvestimento) => {
						tipoInvestimento = {
							...tiposInvestimentosFields,
							[tipoInvestimento.tipo_investimento]: {
								valor: tipoInvestimento.percentual,
								isSort: true,
							},
						}

						return tipoInvestimento
					},
					{}
				)

				const { tipos_investimento, ...clientePf } = { ...dataItem }
				return { ...clientePf, ...tiposInvestimentos }
			})

			if (dataTransformado.length > 0) {
				let totaisValores = {}

				this.columns
					.slice(4, this.columns.length - 1)
					.forEach((itemTipoInvestimento, index) => {
						if (data.media_investimentos_por_ativo[itemTipoInvestimento.name]) {
							totaisValores[itemTipoInvestimento.name] = {
								valor:
									data.media_investimentos_por_ativo[itemTipoInvestimento.name],
								isSort: false,
							}
						}
					})

				totaisValores['dados_investimento_renda_fixa'] = {
					media: data.media_investimentos_por_ativo['Renda Fixa'],
				}

				dataTransformado.forEach((itemDataTransformado) => {
					itemDataTransformado['dados_investimento_renda_fixa'] = {
						...itemDataTransformado.dados_investimento_renda_fixa,
						totalGlobalPorcentagem: itemDataTransformado['Renda Fixa'] || {
							valor: 0,
							isSort: false,
						},
					}
				})

				dataTransformado.push({ nome: 'Media', ...totaisValores })
			}

			// console.log(dataTransformado)
			this.totalRegistros = data.count

			return dataTransformado
		},

		iniciartableInvestimentos() {
			this.tableInvestimentos = this.iniciarTabelaGenerica(
				'#table_investimentos',
				this.columns,
				`${window.location.origin}${URLS.investimentos_clientes_pf_api}`,
				(data) => {
					return this.formatDataInvestimentosTable(data)
				},
				(data) => this.formDataInvestimentosTotal(data)
			)
		},

		openModalOnBotton(dados) {
			// this.openModal(id, politica)
		},

		formatValorBr(valor) {
			const numero = Number(valor)

			if (!Number.isNaN(numero)) {
				return (
					'$ ' + valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
				)
			}
		},

		formDataInvestimentosTotal(data) {
			return data.count ?? 0
		},

		async setValuesInvestimentos() {
			const responseMetadadosInvestimentos = await getMetaDadosInvestimentos()

			this.optionsOfficers = responseMetadadosInvestimentos?.todos_users
				.map((itemUser) => {
					return {
						label: itemUser.name,
						value: itemUser.id,
					}
				})
				.filter((itemUser) => itemUser.label && itemUser.label.length > 1)

			const tiposInvestimentos =
				responseMetadadosInvestimentos.tipos_investimento
			this.columns.push(
				...tiposInvestimentos
					.filter((tipoInvestimentos) => tipoInvestimentos !== 'Renda Fixa')
					.map((tipoInvestimentoColumn) => {
						return {
							id: tipoInvestimentoColumn,
							name: tipoInvestimentoColumn,
							width: '5rem',
							minWidth: '5rem',
							attributes: {
								'contet-column': true,
								title: tipoInvestimentoColumn,
							},
							formatter: (cell, row) => {
								if (cell && !isNaN(cell.valor)) {
									const valorPorcetagemInvestimento = cell.valor.toPrecision(3)

									if (row?._cells[2]?.data === 'Media') {
										return htmlGrid(
											`
									<div style='width: 100%; height: 50px; '> 
										<span style='color: black; font-weight: bold;'>${valorPorcetagemInvestimento}%</span>
									</div>	
									`
										)
									}

									const lightnessMax = 90
									const lightnessMin = 30

									const lightness =
										lightnessMax -
										(cell.valor / 100) * (lightnessMax - lightnessMin)
									const colorBg = `hsl(0, 80%, ${lightness}%)`

									return htmlGrid(
										`
								<div style='background-color:${colorBg}; width: 100%; height: 100%; color: white;'> 
									<span>${valorPorcetagemInvestimento}%</span>
								</div>	
								`
									)
								} else {
									return htmlGrid(`<span style='color: black;'>0</span>`)
								}
							},
							sort: {
								compare(a, b) {
									let auxA = a?.valor
									let auxB = b?.valor

									if (a && !a.isSort) {
										return 0
									}

									if (isNaN(auxA)) {
										auxA = 0.0
									}

									if (isNaN(auxB)) {
										auxB = 0.0
									}

									if (auxA > auxB) {
										return 1
									} else if (auxA < auxB) {
										return -1
									}
									return 0
								},
							},
						}
					})
			)

			const urlParams = new URLSearchParams(window.location.search)
			const users = urlParams.get('user')?.trim().split(',')
			const page = urlParams.get('page')?.trim()
			const page_size = urlParams.get('page_size')?.trim()

			this.model.currentPage = page ?? this.model.currentPage
			this.model.paginationLimitInvestimentos =
				page_size ?? this.model.paginationLimitInvestimentos

			this.model.filtrarInvestimentos =
				users
					?.map((itemUser) => parseInt(itemUser))
					.filter((itemUser) => {
						return this.optionsOfficers.find(
							(itemOfficer) => itemOfficer.value === itemUser
						)
					}) || []
		},

		setQueryUrl() {
			this.query = '?'
			const idUsers = this.model?.filtroInvestimentos?.map(
				(user) => user.value || user
			)

			const userJoin = idUsers?.filter((idUser) => idUser)?.join(',')

			if (userJoin) {
				this.query += `user=${userJoin}`
				this.query += '&'
			}

			this.query += `page=${this.model.currentPage}&page_size=${this.model.paginationLimitInvestimentos}`

			window.history.pushState({}, '', this.query)
		},

		filtrarInvestimentos() {
			this.tableInvestimentos?.forceRender()
			this.closeFiltroInvestimentos()
			//window.location.href = `${window.location.origin}/clientes/investimentos/clientes_pf/${this.query}`
		},

		limparFiltroInvestimentos() {
			this.model = {
				filtroInvestimentos: null,
			}
			window.location.href = `${window.location.origin}/clientes/investimentos/clientes_pf/`
		},

		closeFiltroInvestimentos() {
			const filtroInvestimentos = document.getElementById(
				'close_filtro_investimentos'
			)

			if (filtroInvestimentos) {
				filtroInvestimentos.click()
			}
		},

		getScrollElement() {
			return this.$refs.gridContainer?.querySelector('.gridjs-wrapper')
		},
		scrollUp() {
			window.scrollBy({ top: -50, behavior: 'smooth' })
		},
		scrollDown() {
			window.scrollBy({ top: 50, behavior: 'smooth' })
		},
		scrollLeft() {
			const container = this.getScrollElement()
			if (container) container.scrollBy({ left: -50, behavior: 'smooth' })
		},
		scrollRight() {
			const container = this.getScrollElement()
			if (container) container.scrollBy({ left: 50, behavior: 'smooth' })
		},
	},
	computed: {},
	watch: {
		'model.paginationLimitInvestimentos': function (val) {
			if (this.tableInvestimentos) {
				let currentLimit = val
				if (val === 'todos') currentLimit = this.totalRegistros

				this.tableInvestimentos
					.updateConfig({
						pagination: {
							enabled: true,
							limit: currentLimit,
							summary: false,
							server: {
								url: (urlApi, page, limit) => {
									this.model.currentPage = ++page
									this.model.paginationLimitInvestimentos = currentLimit
									this.setQueryUrl()
									return `${window.location.origin}${URLS.investimentos_clientes_pf_api}${this.query}`
								},
							},
						},
					})
					.forceRender()
			}
		},
	},

	async mounted() {
		this.setValuesInvestimentos()

		setTimeout(() => {
			this.iniciartableInvestimentos()
		}, 1000)
	},
	delimiters: ['{*', '*}'],
}
